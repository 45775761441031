import { AdminLogin } from "./admin-login";
import { H1 } from "shared-web-react/dist/widgets/text";
import { useAuthData } from "shared/dist/auth-data";

export function Welcome(): React.JSX.Element {
  const ad = useAuthData();
  if (!ad) {
    return <AdminLogin />;
  }
  return (
    <div className="max-w-xl overflow-auto">
      <H1>Admin Home</H1>
      <p>Token:</p>
      <p>{ad.token}</p>
    </div>
  );
}
