import {
  AdminBlastHistory,
  AdminMessageBlast,
  AdminNewUserMessageBlast,
  AdminOpenMessageBlast,
} from "./screens/admin-message-blast";
import {
  AdminDiscoveryCounts,
  AdminDiscoveryDebugger,
  AdminDiscoveryDebuggerDetailQuery,
} from "./screens/discovery/debugger";
import { AdminDiscoveryForUserScreen, AdminUserDiscoveryDebugger } from "./screens/admin-discovery";
import { AdminEventDetail, AdminEvents } from "./screens/admin-events";
import { AdminMediaDetail, AdminMediaList } from "./screens/admin-media";
import { EnvProvider, useHasuraEndpoint } from "shared/dist/util/env";
import { Provider as RollbarProvider, useRollbar } from "@rollbar/react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { ToastProvider, useAddToast } from "shared-web-react/dist/widgets/toast-provider";
import {
  UserDetail,
  UserDetailBasics,
  UserDetailDelete,
  UserDetailRelationships,
} from "./screens/user-detail";
import { useLogout, useMyId } from "shared/dist/auth-data";

import { AdminIdMedia } from "./screens/id-media";
import { AdminMain } from "./screens/admin-main";
import { AdminNotifications } from "./screens/notifications";
import { AdminOpenPushNotificationBlast } from "./screens/message-blast/admin-push-blast";
import { AdminRelationshipsDashboard } from "./screens/relationship-list";
import { AdminVouches } from "./screens/admin-vouches";
import { AssetsProvider } from "shared-web-react/dist/util/assets-provider";
import { ConfirmProvider } from "shared-web-react/dist/widgets/confirm-provider";
import { GlobalFeatureFlags } from "./screens/feature-flags";
import { ImageCacheProvider } from "shared-web-react/dist/widgets/lazy-image";
import { Invitations } from "./screens/invitations";
import { Logout } from "shared-web-react/dist/screens/logout";
import { MakeApolloClient } from "shared/dist/apollo";
import { NuclearOptions } from "./screens/nuclear-options";
import { ProfileGrouping } from "./screens/profile-grouping";
import { ProfileGroupingDetail } from "./screens/profile-grouping-detail";
import React from "react";
import { RouterError } from "shared-web-react/dist/widgets/errors";
import { SpinnerFullScreen } from "shared-web-react/dist/widgets/spinner";
import { Stats } from "./screens/stats";
import { UserDetailPhotos } from "./screens/media/user-photo-list";
import { UserList } from "./screens/user-list";
import { WebAuthDataImplementation } from "shared-web-react/dist/util/web-auth";
import { Welcome } from "./screens/welcome";
import { adminRoutes } from "./util/admin-routes";
// Hackish way to get the logo to show up in the admin page.
import logoCircleUnpadded from "../../frontend-web-bio-page/src/assets/icon-logos/c-interlock.png";
import logoInterlock from "../../frontend-web-bio-page/src/assets/neon-logos/candid-neon-white.png";
import logoNeonWhite from "../../frontend-web-bio-page/src/assets/neon-logos/candid-neon-white.png";
import { useMixpanelIdentify } from "shared/dist/mixpanel-client";

const router = createBrowserRouter([
  {
    path: "/",
    element: <AdminMain />,
    errorElement: <RouterError caption={"Unknown Error"} />,
    children: [
      {
        path: adminRoutes.LOGOUT.path,
        element: <Logout />,
      },
      { index: true, element: <Welcome /> },
      {
        path: adminRoutes.EVENTS.path,
        element: <AdminEvents />,
      },
      {
        path: adminRoutes.EVENTS.DETAIL.path,
        element: <AdminEventDetail />,
      },
      {
        path: adminRoutes.USERS.path,
        element: <UserList />,
      },
      {
        path: adminRoutes.USERS.DETAIL.path,
        element: <UserDetail />,
        children: [
          { index: true, element: <UserDetailBasics /> },
          {
            path: adminRoutes.USERS.DETAIL.$.DISCOVERY.relativePath,
            element: <AdminDiscoveryForUserScreen />,
          },
          { path: adminRoutes.USERS.DETAIL.$.PHOTOS.relativePath, element: <UserDetailPhotos /> },
          { path: adminRoutes.USERS.DETAIL.$.DELETE.relativePath, element: <UserDetailDelete /> },
          {
            path: adminRoutes.USERS.DETAIL.$.RELATIONSHIPS.relativePath,
            element: <UserDetailRelationships />,
          },
          {
            path: adminRoutes.USERS.DETAIL.$.VOUCHES.relativePath,
            element: <AdminVouches />,
          },
          {
            path: adminRoutes.USERS.DETAIL.$.ID_MEDIA.relativePath,
            element: <AdminIdMedia />,
          },
        ],
      },
      {
        path: adminRoutes.MESSAGE_BLAST.path,
        element: <AdminMessageBlast />,
        children: [
          { index: true, element: <AdminOpenMessageBlast /> },
          {
            path: adminRoutes.MESSAGE_BLAST.$.PUSH_BLAST.relativePath,
            element: <AdminOpenPushNotificationBlast />,
          },
          {
            path: adminRoutes.MESSAGE_BLAST.$.BLAST_HISTORY.relativePath,
            element: <AdminBlastHistory />,
          },
          {
            path: adminRoutes.MESSAGE_BLAST.$.NEW_USERS.relativePath,
            element: <AdminNewUserMessageBlast />,
          },
        ],
      },
      {
        path: adminRoutes.FEATURE_FLAGS.path,
        element: <GlobalFeatureFlags />,
      },
      {
        path: adminRoutes.MEDIA.path,
        element: <AdminMediaList />,
      },
      {
        path: adminRoutes.MEDIA.DETAIL.path,
        element: <AdminMediaDetail />,
      },
      {
        path: adminRoutes.MEDIA.EDIT.path,
        element: <AdminMediaDetail />,
      },
      {
        path: adminRoutes.DISCOVERY_STATS.DEBUGGER.path,
        element: <AdminDiscoveryDebugger />,
        children: [
          { index: true, element: <AdminDiscoveryCounts /> },
          {
            path: adminRoutes.DISCOVERY_STATS.DEBUGGER.$.DETAIL_QUERY.relativePath,
            element: <AdminDiscoveryDebuggerDetailQuery />,
          },
        ],
      },
      {
        path: adminRoutes.DISCOVERY_USER.path,
        element: <AdminUserDiscoveryDebugger />,
        children: [
          { index: true, element: <div>Discovery Home</div> },
          {
            path: adminRoutes.DISCOVERY_USER.$.DETAILS.relativePath,
            element: <AdminDiscoveryForUserScreen />,
          },
        ],
      },
      {
        path: adminRoutes.USERS.DETAIL.path,
        element: <UserDetail />,
      },
      {
        path: adminRoutes.INVITATIONS.path,
        element: <Invitations />,
      },
      {
        path: adminRoutes.STATS.path,
        element: <Stats />,
      },
      {
        path: adminRoutes.NOTIFICATIONS.path,
        element: <AdminNotifications />,
      },
      {
        path: adminRoutes.RELATIONSHIPS.FILTER.path,
        element: <AdminRelationshipsDashboard />,
      },
      {
        path: adminRoutes.RELATIONSHIPS.path,
        element: <AdminRelationshipsDashboard />,
      },
      {
        path: adminRoutes.NUCLEAR_OPTIONS.path,
        element: <NuclearOptions />,
      },
      {
        path: adminRoutes.PROFILE_GROUPING.path,
        element: <ProfileGrouping />,
        children: [
          { index: true, element: <div>Grouping Home</div> },
          {
            path: adminRoutes.PROFILE_GROUPING.$.DETAIL.relativePath,
            element: <ProfileGroupingDetail />,
          },
        ],
      },
    ],
  },
]);

function MixpanelInit(): React.JSX.Element {
  useMixpanelIdentify();
  return <></>;
}

function AppInner(): React.JSX.Element {
  const id = useMyId();
  const loggedIn = !!id;
  console.log("🚀 ~ file: App.tsx:201 ~ AppInner ~ loggedIn", loggedIn, window.location.pathname);
  console.log("🚀 ~ file: App.tsx:203 ~ AppInner ~ id", id);
  const logout = useLogout();
  const hasuraEndpoint = useHasuraEndpoint();
  const rollbar = useRollbar();
  React.useEffect(() => {
    rollbar.configure({
      payload: {
        person: {
          id: id,
        },
      },
    });
  }, [id, rollbar]);
  const addToast = useAddToast();
  return (
    <>
      <MakeApolloClient
        uri={hasuraEndpoint}
        resetTokenOnExpiration={() => {
          console.log("🚀 ~ file: App.tsx ~ line 96 ~ AppInner ~ logout");
          logout();
        }}
        showError={(apolloError) => {
          console.error(apolloError);
          try {
            if (apolloError.errorId === "APOLLO_NETWORK_ERROR") {
              addToast({
                unitary: true,
                color: "error",
                content:
                  "Server Connection error.  Query: " +
                  apolloError.operationName +
                  " Error: " +
                  apolloError.userFacingText,
              });
            } else if (apolloError.errorId === "APOLLO_GQL_ERROR") {
              try {
                // This is hackish but errors were causing an infinite reload loop.
                // For this query, we can safely ignore errors.  Code in thread-messages.tsx
                console.log("app.tsx:115", apolloError.operation?.getContext());
                if (apolloError.operation?.getContext()?.["noToast"]) {
                  return;
                }
                if (apolloError.operationName === "ThreadOgTags") {
                  return;
                }
                console.error(
                  apolloError.errorId +
                    ": " +
                    (apolloError.operationName ?? apolloError.userFacingText ?? ""),
                  {
                    returnedData: apolloError.returnedData,
                    operationName: apolloError.operation,
                    errorDetails: apolloError.errorDetails,
                    query: apolloError.operation?.query?.toString(),
                    variables: apolloError.operation?.variables,
                    returnedErrors: apolloError.returnedErrors,
                    path: window.location.pathname,
                  }
                );
              } catch (e: any) {
                console.error("Could not log GQL Erorr:", e);
              }
              addToast({
                unitary: true,
                content:
                  "Server API error.  Query: " +
                  (apolloError.operationName ??
                    "<missing op>" + " Error: " + apolloError.userFacingText),
                color: "error",
              });
            }
          } finally {
            // setTimeout(() => setApiErrorToast(null), 10000);
          }
        }}
      >
        <RouterProvider router={router} />
        <MixpanelInit />
      </MakeApolloClient>
    </>
  );
}

const rollbarConfig = {
  accessToken: import.meta.env.REACT_APP_ROLLBAR_ACCESS_TOKEN!,
  environment: import.meta.env.REACT_APP_ENV,
  captureUncaught: true,
  captureUnhandledRejections: true,
  autoInstrument: true,
  enabled: !!import.meta.env.REACT_APP_ENV.match(/.*prod.*/i),
};
console.log("🚀 - file: App.tsx:301 - rollbarConfig:", rollbarConfig);

export function App() {
  React.useEffect(() => {
    setTimeout(() => window.location.reload(), 1000 * 60 * 60 * 12);
  }, []);
  return (
    <AssetsProvider
      assets={{
        logoCircleNoPadding: logoCircleUnpadded,
        logoCirclePadded: logoInterlock,
        logoWhiteFullWord: logoNeonWhite,
      }}
    >
      <EnvProvider
        env={{
          REACT_APP_ENV: import.meta.env.REACT_APP_ENV!,
          REACT_APP_HASURA_ENDPOINT: import.meta.env.REACT_APP_HASURA_ENDPOINT!,
          REACT_APP_MIXPANEL_PROJECT_TOKEN: import.meta.env.REACT_APP_MIXPANEL_PROJECT_TOKEN!,
          REACT_APP_PRODUCT_NAME: import.meta.env.REACT_APP_PRODUCT_NAME!,
          REACT_APP_BASE_URI: import.meta.env.REACT_APP_BASE_URI,
          REACT_APP_PERSONA_ENV_ID: import.meta.env.REACT_APP_PERSONA_ENV_ID!,
          REACT_APP_PERSONA_TEMPLATE_ID: import.meta.env.REACT_APP_PERSONA_TEMPLATE_ID!,
        }}
      >
        <ImageCacheProvider>
          <ToastProvider toastClassName="toast-end">
            <ConfirmProvider>
              <RollbarProvider config={rollbarConfig}>
                <WebAuthDataImplementation keyOverride="admin_page">
                  <AppInner />
                  <SpinnerFullScreen />
                </WebAuthDataImplementation>
              </RollbarProvider>
            </ConfirmProvider>
          </ToastProvider>
        </ImageCacheProvider>
      </EnvProvider>
    </AssetsProvider>
  );
}
