import { AdminUserAvatar, AdminUserPicker, IdSpan } from "../util/widgets";
import { H2, H4 } from "shared-web-react/dist/widgets/text";
import {
  Relationship_Statuses_Enum,
  Relationship_Types_Enum,
  useAdminAddRelationshipMutation,
  useAdminRelationshipsQuery,
  useAdminRemoveRelationshipMutation,
  useAdminUpdateRelationshipMutation,
} from "../__generated__/apollo-hooks";
import { Spinner, SpinnerCentered } from "shared-web-react/dist/widgets/spinner";
import { classNames, filterNulls, uniqWith } from "shared/dist/util";

import { DateTime } from "luxon";
import { EnumSelect } from "shared-web-react/dist/widgets/enum-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Paginator } from "shared-web-react/dist/widgets/paginator";
import React from "react";
import { SpinnerButton } from "shared-web-react/dist/widgets/spinner-button";
import clsx from "clsx";
import { faXmarkCircle } from "@fortawesome/pro-solid-svg-icons";
import { isValidUuid } from "shared/dist/util";
import { useConfirm } from "shared-web-react/dist/widgets/confirm-provider";
import { useDebounce } from "use-debounce";
import { useParams } from "react-router-dom";

export function AdminRelationshipsDashboard(): React.JSX.Element {
  return (
    <div className="space-y-2 py-4 flex flex-col justify-stretch max-h-screen  relative">
      <H2>Relationships</H2>
      <div className={classNames("divider")} />
      <AddRelationship />
      <div className={classNames("divider")} />
      <AdminRelationshipsList />
    </div>
  );
}

function AddRelationship(): React.JSX.Element {
  const [mutate, { loading, error }] = useAdminAddRelationshipMutation();
  const [fromUserId, setFromUserId] = React.useState<null | string>(null);
  const [toUserId, setToUserId] = React.useState<null | string>(null);
  const [relType, setRelType] = React.useState<Relationship_Types_Enum>(
    Relationship_Types_Enum.Like
  );
  const [relStatus, setRelStatus] = React.useState<Relationship_Statuses_Enum>(
    Relationship_Statuses_Enum.Requested
  );
  const ready = fromUserId && toUserId && fromUserId !== toUserId && !loading;
  return (
    <div className={classNames("flex flex-row justify-start items-center gap-4")}>
      <H4>new relationship</H4>
      <span>
        From:{" "}
        <AdminUserPicker onSelect={(e) => e && setFromUserId(e?.id)} inputClassName="input-sm" />
      </span>
      <span>
        To: <AdminUserPicker onSelect={(e) => e && setToUserId(e?.id)} inputClassName="input-sm" />
      </span>
      <EnumSelect
        value={relType}
        possibleValues={Relationship_Types_Enum}
        inputClassName="min-w-[5rem] "
        onChange={async (e) => e && setRelType(e)}
      />
      <EnumSelect
        value={relStatus}
        inputClassName="min-w-[5rem] "
        possibleValues={Relationship_Statuses_Enum}
        onChange={async (e) => e && setRelStatus(e)}
      />
      <SpinnerButton
        loading={loading}
        disabled={loading || !ready}
        className={classNames("btn btn-secondary  btn-sm")}
        onClick={() => {
          if (!ready) {
            return;
          }
          mutate({
            refetchQueries: ["AdminRelationships"],
            variables: {
              insert: {
                from_user_id: fromUserId,
                to_user_id: toUserId,
                type: relType,
                status: relStatus,
              },
            },
          });
        }}
      >
        add
      </SpinnerButton>
      {error && <span className={classNames("text-erorr")}>{error.message}</span>}
    </div>
  );
}

function AdminRelationshipsList(): React.JSX.Element {
  const limit = 25;
  const [page, setPage] = React.useState(1);
  const { filter: paramsFilter } = useParams();
  const paramsFilterIsUuid = isValidUuid(paramsFilter);
  const [filter, setFilter] = React.useState(paramsFilterIsUuid ? paramsFilter : "");
  const [types, setTypes] = React.useState(Object.values(Relationship_Types_Enum));
  const [statuses, setStatuses] = React.useState([
    Relationship_Statuses_Enum.Accepted,
    Relationship_Statuses_Enum.EndedByFromUser,
    Relationship_Statuses_Enum.EndedByToUser,
    Relationship_Statuses_Enum.Rejected,
    Relationship_Statuses_Enum.Requested,
  ]);
  const [debouncedFilter, { isPending }] = useDebounce(filter, 1000);
  const [updateRel] = useAdminUpdateRelationshipMutation();
  const [removeRel] = useAdminRemoveRelationshipMutation();
  const confirm = useConfirm();
  const { data, loading, refetch } = useAdminRelationshipsQuery({
    variables: {
      limit,
      offset: (page - 1) * limit,
      types,
      filter: `%${debouncedFilter}%`,
      relationship_id: paramsFilterIsUuid ? [paramsFilter] : [],
      statuses,
    },
  });
  if (loading) {
    return <SpinnerCentered />;
  }
  const count = data?.relationships_aggregate?.aggregate?.count ?? 0;
  const pageCount = Math.ceil(count / limit);

  const relationshipList =
    (isValidUuid(filter) ? data?.filtered_relationship : data?.relationships) ?? [];
  return (
    <>
      <div className="flex flex-row justify-start items-center gap-2">
        Total: {count}
        <Paginator {...{ page, pageCount, setPage }} />
        <div className="form-control">
          <div className="join">
            <input
              type="text"
              placeholder="Search…"
              className="join-item input input-bordered"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
            <button onClick={() => setFilter("")} className="join-item btn">
              reset
            </button>
            <button onClick={() => refetch()} className="join-item btn">
              reload
            </button>
          </div>
        </div>
        {(loading || isPending()) && <Spinner />}
      </div>
      <div className={classNames("flex flex-row gap-2")}>
        {Object.values(Relationship_Statuses_Enum).map((status) => (
          <button
            className={classNames(
              "btn btn-sm",
              statuses.includes(status) ? "btn-primary" : "btn-outline"
            )}
            key={status}
            onClick={() => {
              setStatuses(
                filterNulls(
                  uniqWith(
                    [
                      ...statuses.filter((t) => t !== status),
                      statuses.includes(status) ? null : status,
                    ],
                    (a, b) => a === b
                  )
                )
              );
            }}
          >
            {status}
          </button>
        ))}
      </div>
      <div className={classNames("flex flex-row gap-2")}>
        {Object.values(Relationship_Types_Enum).map((type) => (
          <button
            className={classNames(
              "btn btn-sm",
              types.includes(type) ? "btn-primary" : "btn-outline"
            )}
            key={type}
            onClick={() => {
              setTypes(
                filterNulls(
                  uniqWith(
                    [...types.filter((t) => t !== type), types.includes(type) ? null : type],
                    (a, b) => a === b
                  )
                )
              );
            }}
          >
            {type}
          </button>
        ))}
      </div>
      <div className="overflow-auto w-full ">
        <table className="table w-full mb-8">
          {/* head */}
          <thead>
            <tr>
              {/* <th className="">Total: {count}</th> */}
              <th>from user</th>
              <th>to user</th>
              <th>type</th>
              <th>status</th>
              <th>delete</th>
              <th>created</th>
              <th>updated</th>
              <th>id</th>
            </tr>
          </thead>
          <tbody>
            {relationshipList.map(
              (rel, idx) =>
                rel.from_user_summary?.slug &&
                rel.to_user_summary?.slug && (
                  <tr key={idx}>
                    <td>
                      <AdminUserAvatar slug={rel.from_user_summary.slug} />
                    </td>
                    <td>
                      <AdminUserAvatar slug={rel.to_user_summary.slug} />
                    </td>
                    <td>
                      <EnumSelect
                        inputClassName="min-w-[5rem]"
                        possibleValues={Relationship_Types_Enum}
                        onChange={async (e) =>
                          updateRel({
                            refetchQueries: ["AdminRelationships"],
                            variables: { id: rel.id, update: { type: e } },
                          })
                        }
                        value={rel.type}
                      />
                    </td>
                    <td>
                      <EnumSelect
                        possibleValues={Relationship_Statuses_Enum}
                        inputClassName="min-w-[5rem]"
                        onChange={async (e) =>
                          updateRel({
                            refetchQueries: ["AdminRelationships"],
                            variables: { id: rel.id, update: { status: e } },
                          })
                        }
                        value={rel.status}
                      />
                    </td>
                    <td>
                      <SpinnerButton
                        onClickWrapped={async () =>
                          confirm({
                            title: "Permanently Delete?",
                            content: `${rel.type} between: ${rel.from_user_summary?.slug} and ${rel.to_user_summary?.slug}}`,
                            onOk() {
                              removeRel({
                                refetchQueries: ["AdminRelationships"],
                                variables: { id: rel.id },
                              });
                            },
                          })
                        }
                        className={clsx("flex justify-center items-center")}
                      >
                        <FontAwesomeIcon
                          icon={faXmarkCircle}
                          className={clsx("text-error")}
                          size="2x"
                        />
                      </SpinnerButton>
                    </td>
                    <td>
                      {DateTime.fromISO(rel.created_at).toLocaleString(DateTime.DATETIME_SHORT)}
                    </td>
                    <td>
                      {DateTime.fromISO(rel.updated_at).toLocaleString(DateTime.DATETIME_SHORT)}
                    </td>
                    <td className={classNames("max-w-[8rem] overflow-x-hidden whitespace-nowrap")}>
                      <IdSpan id={rel.id} />
                    </td>
                  </tr>
                )
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}
