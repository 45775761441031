import {
  Media_Uploads_Order_By,
  useAdminMediaForUserQuery,
} from "../../__generated__/apollo-hooks";

import { AdminMediaTable } from "../admin-media";
import { H4 } from "shared-web-react/dist/widgets/text";
import { Paginator } from "shared-web-react/dist/widgets/paginator";
import React from "react";
import { SpinnerCentered } from "shared-web-react/dist/widgets/spinner";
import { adminRoutes } from "../../util/admin-routes";
import clsx from "clsx";
import { useGetUserId } from "../../util/db-helpers";
import { useTypedParams } from "react-router-typesafe-routes/dom";

export function UserDetailPhotos(): React.JSX.Element {
  const slug = useTypedParams(adminRoutes.USERS.DETAIL).slug;
  const limit = 25;
  const [reverse, setReverse] = React.useState(false);

  const [field, setField] = React.useState<keyof Media_Uploads_Order_By>("created_at");
  const [page, setPage] = React.useState(1);
  const { loading: userIdLoading, id: userId } = useGetUserId(slug);
  const { data, loading } = useAdminMediaForUserQuery({
    skip: !userId,
    pollInterval: 30 * 1000,
    variables: {
      offset: (page - 1) * limit,
      id: userId!,
    }, // eslint-disable-line @typescript-eslint/no-non-null-assertion
  });
  const pageCount = Math.ceil((data?.media_uploads_aggregate?.aggregate?.count ?? 1) / limit);
  if (loading || userIdLoading) return <SpinnerCentered />;

  return (
    <div className="overflow-ao w-full ">
      <div className={clsx("w-64")}>
        <H4>Images:</H4>
      </div>
      <Paginator {...{ page, setPage, pageCount }} />
      <AdminMediaTable
        {...{ mediaItems: data?.media_uploads, setField, setReverse, reverse, field }}
      />
    </div>
  );
}
