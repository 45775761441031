import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "shared-web-react/dist/widgets/floating-ui/tooltip";
import {
  useAdminDeleteInfoForArchiveQuery,
  useAdminDeleteResetOnErrorMutation,
  useAdminDeleteUserPurgeMutation,
  useAdminDeleteUserSetDeletedAtMutation,
  useAdminDeleteUserThreadsToDeleteQuery,
} from "../__generated__/apollo-hooks";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { SpinnerButton } from "shared-web-react/dist/widgets/spinner-button";
import { adminRoutes } from "../util/admin-routes";
import clsx from "clsx";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import { useAddToast } from "shared-web-react/dist/widgets/toast-provider";
import { useConfirm } from "shared-web-react/dist/widgets/confirm-provider";
import { useMyRoles } from "shared/dist/auth-data";
import { useNavigate } from "react-router-dom";

const superAdminContext = { headers: { "x-hasura-role": "super_admin_user" } };
export type UserDeleteButtonProps = { userId: string; slug: string };
export function UserPermanentlyDeleteButton({
  userId,
  slug,
}: UserDeleteButtonProps): React.JSX.Element {
  const confirm = useConfirm();
  const addToast = useAddToast();
  const [setDeletedAt] = useAdminDeleteUserSetDeletedAtMutation();
  const userSummaryQuery = useAdminDeleteInfoForArchiveQuery({
    variables: { user_id: userId },
    context: superAdminContext,
    fetchPolicy: "cache-first",
  });
  const userSummary = userSummaryQuery.data?.users_by_pk;
  const userThreadsToKillResult = useAdminDeleteUserThreadsToDeleteQuery({
    variables: { user_id: userId },
    context: superAdminContext,
  });
  const [resetMutation] = useAdminDeleteResetOnErrorMutation();
  const [fullPurge] = useAdminDeleteUserPurgeMutation();
  const navigate = useNavigate();
  const enabled = useMyRoles()?.includes("super_admin_user");

  const deleteUserOnConfirm = React.useCallback(async () => {
    if (!userSummary) { return } // prettier-ignore
    if (!userSummary.itu_country_code || !userSummary.phone_number) {
      addToast({
        content: "Missing phone number for users.. cannot delete",
        color: "warning",
      });
      return;
    }
    if (!userSummary.email) {
      addToast({
        content: "Missing email for users.. cannot delete",
        color: "warning",
      });
      return;
    }
    const setDeletedResult = await setDeletedAt({
      variables: {
        user_id: userId,
      },
      context: superAdminContext,
    });
    console.log("🚀 ~ file: user-delete.tsx:68 - set Deleted complete", setDeletedResult?.data);
    try {
      console.log("🚀 ~ file: user-delete.tsx:77 ~ about to delete");
      const result = await fullPurge({
        variables: {
          user_id: userId,
          email: userSummary.email,
          itu_country_code: userSummary.itu_country_code,
          phone_number: userSummary.phone_number,
          thread_ids: userThreadsToKillResult?.data?.threads?.map((t) => t.id) ?? [],
        },
        context: superAdminContext,
      });
      console.log(
        "🚀 ~ file: user-delete.tsx:84 ~ deleteUserOnConfirm ~ result:",
        result?.data,
        result?.errors
      );
      if (result.errors || !result.data) {
        console.error(
          "🚀 ~ file: user-delete.tsx:91 ~ deleteUserOnConfirm ~ result.errors:",
          result.errors
        );
        await resetMutation({
          variables: { user_id: userId },
          context: superAdminContext,
        });
        addToast({
          content: "failed to delete",
          color: "warning",
        });
        return;
      }
      addToast({
        content: "Slug matched, deleted",
        color: "success",
      });
      setTimeout(() => navigate(adminRoutes.USERS.buildPath({})), 1000);
    } catch (err) {
      console.error("file: user-delete.tsx:87 ~ deleteUserOnConfirm ~ err:", err);
      await resetMutation({
        variables: { user_id: userId },
        context: superAdminContext,
      });
      addToast({
        content: "FAILED TO FULLY DELETE USER.  Talk to Max",
        color: "error",
      });
    }
  }, [
    userSummary,
    setDeletedAt,
    userId,
    addToast,
    fullPurge,
    userThreadsToKillResult?.data?.threads,
    resetMutation,
    navigate,
  ]);
  const deleteUser = React.useCallback(async () => {
    if (!userSummary) { return } // prettier-ignore
    return new Promise((resolve, reject) => {
      confirm({
        title: "Permanently Delete this user?",
        content: "This is irreversible.",
        onCancel: async () => resolve(null),
        onOk: function (): void | Promise<any> {
          try {
            const str = prompt("Please enter the user's slug to continue, without the @ symbol");
            if (str !== slug) {
              addToast({
                content: "Slug did not match, aborting",
                color: "warning",
              });
              return resolve(null);
            } else {
              deleteUserOnConfirm().then(resolve).catch(reject);
            }
          } catch (e) {
            reject(e);
          }
        },
      });
    });
  }, [confirm, addToast, userSummary]);
  return (
    <Tooltip>
      <TooltipTrigger>
        <TooltipContent>
          {enabled ? "Be very careful" : "Only Super Admins can delete users"}
        </TooltipContent>
        <SpinnerButton
          onClickWrapped={deleteUser}
          disabled={!enabled || !userSummary}
          className={clsx("btn btn-error ")}
        >
          <span className={clsx("flex items-center gap-2")}>
            <FontAwesomeIcon icon={faExclamationTriangle} size="2x" />
            Permanently Delete User
          </span>
        </SpinnerButton>
      </TooltipTrigger>
    </Tooltip>
  );
}
