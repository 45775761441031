import {
  AdminInvitationsQuery,
  Invitations_Bool_Exp,
  useAdminAddInvitationsMutation,
  useAdminInvitationsQuery,
  useUpdateInvitationMutation,
} from "../__generated__/apollo-hooks";
import { Spinner, SpinnerFullScreen } from "shared-web-react/dist/widgets/spinner";

import { DateTime } from "luxon";
import { Link } from "react-router-dom";
import React from "react";
import { SpinnerCheckbox } from "shared-web-react/dist/widgets/spinner-checkbox";
import { adminRoutes } from "../util/admin-routes";
import clsx from "clsx";
import { useAddToast } from "shared-web-react/dist/widgets/toast-provider";
import { useConfirm } from "shared-web-react/dist/widgets/confirm-provider";
import { useDebounce } from "use-debounce";

function InvitationNotes({
  invite,
}: {
  invite: AdminInvitationsQuery["invitations"][0];
}): React.JSX.Element {
  const [mutate, { loading }] = useUpdateInvitationMutation();
  const [notes, setNotes] = React.useState(invite.notes ?? "");
  const [pending, setPending] = React.useState(false);
  const [debouncedNotes] = useDebounce(notes, 500);
  const maxLength = 128;
  React.useEffect(() => {
    setPending(true);
  }, [setPending, notes]);
  React.useEffect(() => {
    const trimmedNotes = debouncedNotes.slice(0, maxLength) ?? "";
    setPending(false);
    if (
      trimmedNotes === invite.notes?.slice(0, maxLength) ||
      (trimmedNotes?.length === 0 && invite?.notes?.length === 0)
    ) {
      return;
    }
    mutate({
      update: (cache) => {
        cache.modify({
          id: cache.identify({
            __typename: "invitations",
            id: invite.id,
          }),
          fields: {
            notes() {
              return trimmedNotes;
            },
          },
        });
      },
      variables: {
        id: invite.id,
        update: { notes: trimmedNotes },
      },
    });
  }, [debouncedNotes, mutate, invite]);
  return (
    <div className="join">
      <input
        value={notes}
        onChange={(e) => setNotes(e.target.value)}
        maxLength={10}
        className="input text-primary bg-primary-content input-sm rounded-xs rounded-r-none"
      />
      <div className="input pr-1  w-10 text-primary bg-primary-content input-sm rounded-xs rounded-l-none">
        {(loading || pending) && <Spinner />}
      </div>
    </div>
  );
}

function InvitationRow({
  invite,
}: {
  invite: AdminInvitationsQuery["invitations"][0];
}): React.JSX.Element {
  const ownerSlug = invite.owner_admin_summary?.slug;
  const [mutate] = useUpdateInvitationMutation();
  const inviteeSlug = invite.invitee_admin_summary?.slug;
  return (
    <tr>
      <td>{invite.id}</td>
      <td>{DateTime.fromISO(invite.updated_at).toLocaleString(DateTime.DATETIME_SHORT)}</td>
      <td>
        {ownerSlug && (
          <Link to={adminRoutes.USERS.DETAIL.buildPath({ slug: ownerSlug })}>
            {"@" + ownerSlug}
          </Link>
        )}
      </td>
      <td className={clsx("flex flex-col justify-start")}>
        {invite.recipient_email && (
          <>
            <div>{invite.recipient_email}</div>
            <div className={clsx("text-xs italic")}>
              {invite.recipient_email_sent_at
                ? DateTime.fromISO(invite.recipient_email_sent_at).toLocaleString(
                    DateTime.DATETIME_SHORT_WITH_SECONDS
                  )
                : "unsent"}
            </div>
          </>
        )}
      </td>
      <td>
        {inviteeSlug && (
          <Link to={adminRoutes.USERS.DETAIL.buildPath({ slug: inviteeSlug })}>
            {"@" + inviteeSlug}
          </Link>
        )}
      </td>
      <td>{invite.source}</td>
      <td>
        <InvitationNotes invite={invite} />
      </td>
      <td>
        <SpinnerCheckbox
          checked={invite.is_for_review_users}
          onChangeWrapped={() =>
            mutate({
              variables: {
                id: invite.id,
                update: { is_for_review_users: !invite.is_for_review_users },
              },
            })
          }
        />
      </td>
    </tr>
  );
}

export function Invitations(): React.JSX.Element {
  const limit = 50;
  const [page, setPage] = React.useState(1);
  const [kind, setKind] = React.useState<"all" | "open" | "used">("all");
  const [filter, setFilter] = React.useState("");
  const [debouncedFilter, { isPending }] = useDebounce(filter, 1000);
  const kindFilter =
    kind === "all" ? {} : { invitee_id: { _is_null: kind === "open" ? true : false } };
  const ilike = { _ilike: `%${debouncedFilter}%` };
  const stringFilter: Invitations_Bool_Exp =
    debouncedFilter.trim().length > 0
      ? {
          _or: [
            { invitee_admin_summary: { email: ilike } },
            { invitee_admin_summary: { slug: ilike } },
            { invitee_admin_summary: { screen_name: ilike } },
            { recipient_email: ilike },
          ],
        }
      : {};
  const { data, loading, error, refetch } = useAdminInvitationsQuery({
    variables: {
      filter: { _and: [kindFilter, stringFilter] },
      offset: (page - 1) * limit,
      limit,
    },
  });
  const count = data?.invitations_aggregate?.aggregate?.count ?? 0;
  const pageCount = Math.ceil(count / limit);
  const addToast = useAddToast();
  const [addMutation] = useAdminAddInvitationsMutation();
  const confirm = useConfirm();
  const emailInvitations = React.useCallback(() => {
    const emailMatch = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/i;
    const tokens =
      prompt("Enter a white space separated list of emails to send to")
        ?.split(/\s+/)
        ?.map((s) => s.trim()) ?? [];
    const addresses = tokens.filter((s) => s.match(emailMatch));
    const badAddresses = tokens.filter((s) => !s.match(emailMatch));
    if (!addresses || addresses.length === 0) {
      return;
    }
    confirm({
      title: "Are you sure?",
      content: (
        <div>
          <div>
            Will send to the following addresses
            <ol className={clsx("max-h-8 overflow-y-auto")}>
              {addresses.map((a, idx) => (
                <li key={idx}>{a}</li>
              ))}
            </ol>
          </div>
          {badAddresses.length > 0 && (
            <div>
              Will not send to the following malformed addresses
              <ol className={clsx("max-h-8 overflow-y-auto")}>
                {badAddresses.map((a, idx) => (
                  <li key={idx}>{a}</li>
                ))}
              </ol>
            </div>
          )}
        </div>
      ),
      onOk: async () => {
        addMutation({
          refetchQueries: ["AdminInvitations"],
          variables: {
            invitations: addresses.map((a) => ({ recipient_email: a, notes: undefined })),
          },
        });
        setTimeout(() => refetch(), 5000);
      },
    });
  }, [addMutation, confirm]);
  const addInvites = React.useCallback(() => {
    addMutation({
      refetchQueries: ["AdminInvitations"],
      variables: {
        invitations: [...Array(10)].map(() => ({ notes: undefined })),
      },
    });
  }, [addMutation]);
  React.useEffect(() => {
    if (error) {
      addToast({ content: error.message, color: "error" });
    }
  }, [error, addToast]);
  if (loading && !data?.invitations) {
    return <SpinnerFullScreen />;
  }
  return (
    <div className="pt-4 flex flex-col  space-y-2 max-h-screen overflow-hidden relative">
      <div className="flex flex-row justify-start items-center gap-2">
        <div className="join">
          <button
            onClick={() => setPage(Math.max(page - 1, 1))}
            className="join-item btn"
            disabled={page === 1}
          >
            Previous
          </button>
          <p className="join-item btn btn-disabled">
            Page {page} of {pageCount}
          </p>
          <button
            onClick={() => setPage(Math.min(page + 1, pageCount))}
            className="join-item btn"
            disabled={page === pageCount}
          >
            Next{" "}
          </button>
        </div>
        <select
          className="select select-accent"
          value={kind}
          onChange={(e) => setKind(e.target.value as any)}
        >
          <option value="all">all invitations</option>
          <option value="open">unused invitations</option>
          <option value="used">used invitations</option>
        </select>
        <div className="form-control">
          <div className="join">
            <input
              type="text"
              placeholder="Search…"
              className="join-item input input-bordered"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
            <button onClick={() => setFilter("")} className="join-item btn">
              reset
            </button>
          </div>
        </div>
        <button onClick={emailInvitations} className="btn btn-primary">
          email invitations
        </button>
        <button onClick={addInvites} className="btn btn-primary">
          generate new invites
        </button>
        {(loading || isPending()) && <Spinner />}
      </div>
      <div className="overflow-auto">
        <table className="table ">
          <thead>
            <tr>
              <th>code</th>
              <th>updated at</th>
              <th>owner</th>
              <th>emailed to?</th>
              <th>invitee</th>
              <th>source</th>
              <th>notes</th>
              <th>Is for review user</th>
            </tr>
          </thead>
          <tbody>
            {data?.invitations?.map((invite) => <InvitationRow key={invite.id} invite={invite} />)}
          </tbody>
        </table>
      </div>
    </div>
  );
}
