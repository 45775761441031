import {
  Feature_Flags_Enum,
  useAddGlobalFlagMutation,
  useAddUserFlagMutation,
  useAdminGlobalFlagsQuery,
  useAdminUserFlagsQuery,
  useRemoveGlobalFlagMutation,
  useRemoveUserFlagMutation,
} from "../__generated__/apollo-hooks";
import { H3, H4 } from "shared-web-react/dist/widgets/text";
import { Spinner, SpinnerCentered } from "shared-web-react/dist/widgets/spinner";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "shared-web-react/dist/widgets/floating-ui/tooltip";

import { Link } from "react-router-dom";
import React from "react";
import { adminRoutes } from "../util/admin-routes";
import { classNames } from "shared/dist/util";
import { match } from "ts-pattern";

const Flags = Feature_Flags_Enum;
const flagDescription = (flag: Feature_Flags_Enum) =>
  match(flag)
    .with(Flags.EventsCreateV00, () => "Create Events")
    .with(Flags.EventsForceAddAttendeeV00, () => "Add Attendees To Events")
    .with(Flags.UserToUserInviteV00, () => "User to user invites")
    .with(Flags.NotificationsInAppV00, () => "In App Notifications")
    .with(Flags.IncludeCandidTeamInFeedsV00, () => "Show Candid Team in all feeds")
    .with(Flags.ProfileAndDiscoveryV02, () => "The new (Jan 2024) user profiles")
    .with(Flags.FaceBlurringOnMediaV2, () => "Media Pipeline v2 face blurring")
    .with(Flags.NewsFeedDiscovery_01, () => "News Feed Style Discovery")
    .with(Flags.VouchingV00, () => "Vouch for users and see aggregated vouch data")
    .with(Flags.ExtendedFriendSearchV00, () => "Extended friends search list")
    .with(
      Flags.NativePermissionsV01,
      () => "Use the new native permissions widget for location and push"
    )
    .exhaustive();

function UserFlagToggle({
  state,
  global_override,
  onToggle,
  flag,
}: {
  global_override?: boolean;
  flag: Feature_Flags_Enum;
  onToggle: (newState: boolean) => Promise<any>;
  state: boolean;
}): React.JSX.Element {
  const [loading, setLoading] = React.useState(false);
  return (
    <Tooltip>
      <TooltipTrigger>
        <span className={classNames("flex justify-start gap-2 items-center")}>
          {loading ? (
            <Spinner />
          ) : (
            <input
              type="checkbox"
              onChange={() => {
                setLoading(true);
                onToggle(!state).finally(() => setLoading(false));
              }}
              className="checkbox  checkbox-primary"
              checked={global_override || state}
            />
          )}
          {flagDescription(flag)}
        </span>
      </TooltipTrigger>
      {global_override && (
        <TooltipContent>
          Set for all users{" "}
          <Link className={classNames("link")} to={adminRoutes.FEATURE_FLAGS.buildPath({})}>
            change
          </Link>
        </TooltipContent>
      )}
    </Tooltip>
  );
}

export function AdminUserFlags({ user_id }: { user_id?: null | string }): React.JSX.Element {
  const [addFlag] = useAddUserFlagMutation();
  const [removeFlag] = useRemoveUserFlagMutation();
  const { data, loading } = useAdminUserFlagsQuery({
    // We have to disable these non-null assertions for queries typed to require a
    // non null variable but for which we use the skip check
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { user_id: user_id! },
    skip: !user_id,
  });
  const userFlags = data?.user_feature_flags?.map((f) => f.feature_id);
  if (!user_id) {
    return <></>;
  }
  return (
    <div className={classNames("flex flex-col justify-center items-start gap-2")}>
      <H4 className={classNames("mr-3")}>Activated Features for this user:</H4>
      {loading && !data?.user_feature_flags ? (
        <Spinner />
      ) : (
        Object.values(Flags)
          .filter(
            // Exclude global only flags..
            (flag) => flag !== Flags.IncludeCandidTeamInFeedsV00
          )
          .map((flag) => (
            <UserFlagToggle
              key={flag}
              flag={flag}
              onToggle={(newState) =>
                newState
                  ? addFlag({
                      refetchQueries: ["AdminUserFlags"],
                      variables: { user_id, flag },
                    })
                  : removeFlag({
                      refetchQueries: ["AdminUserFlags"],
                      variables: { user_id, flag },
                    })
              }
              state={!!userFlags?.includes(flag)}
            />
          ))
      )}
    </div>
  );
}

export function GlobalFeatureFlags(): React.JSX.Element {
  const [addFlag] = useAddGlobalFlagMutation();
  const [removeFlag] = useRemoveGlobalFlagMutation();
  const { data, loading } = useAdminGlobalFlagsQuery();
  const globalFlags = data?.global_feature_flags?.map((f) => f.feature_id) ?? [];
  return (
    <div className={classNames("space-y-2")}>
      <H3>Global Feature Flags</H3>
      <p className={classNames("italic")}>
        note that settings these flags will activate the feature for all users
      </p>
      {loading && !data?.global_feature_flags ? (
        <SpinnerCentered />
      ) : (
        <ul className={classNames("space-y-2 list-outside w-full list-disc")}>
          {Object.values(Flags).map((flag) => (
            <li key={flag}>
              <UserFlagToggle
                flag={flag}
                onToggle={async (newState) =>
                  confirm("Are you sure?  This will override all users")
                    ? newState
                      ? addFlag({
                          refetchQueries: ["AdminGlobalFlags"],
                          variables: { flag },
                        })
                      : removeFlag({
                          refetchQueries: ["AdminGlobalFlags"],
                          variables: { flag },
                        })
                    : null
                }
                state={!!globalFlags?.includes(flag)}
              />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
