import { ColHeader, IdSpan } from "../util/widgets";
import { Maybe, filterNulls } from "shared/dist/util";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "shared-web-react/dist/widgets/floating-ui/tooltip";
import { Vouch_List_DetailsFragment, useAdminVouchListQuery } from "../__generated__/apollo-hooks";

import { Avatar } from "shared-web-react/dist/widgets/avatar";
import { DateTime } from "luxon";
import { H4 } from "shared-web-react/dist/widgets/text";
import { Link } from "react-router-dom";
import React from "react";
import { SpinnerCentered } from "shared-web-react/dist/widgets/spinner";
import { adminRoutes } from "../util/admin-routes";
import clsx from "clsx";
import { match } from "ts-pattern";
import { useGenderLookup } from "shared-web-react/dist/helpers/gender";
import { useTypedParams } from "react-router-typesafe-routes/dom";

type VouchSummary = {
  id: string;
  is_author: boolean;
  counterparty_slug: string;
  counterparty_gender: string;
  interactions: string;
  created_at: DateTime;
};

function useMkVouchSummary(isAuthor: boolean) {
  const genderLookup = useGenderLookup();
  return (vouch: Maybe<Vouch_List_DetailsFragment>): VouchSummary | null =>
    vouch
      ? {
          id: vouch.id,
          is_author: isAuthor,
          counterparty_gender:
            genderLookup?.data?.[
              (isAuthor
                ? vouch.subject_admin_summary?.gender_id
                : vouch.author_admin_summary?.gender_id) ?? ""
            ]?.name?.en_US ?? "<missing gender>",
          counterparty_slug:
            (isAuthor ? vouch.subject_admin_summary?.slug : vouch.author_admin_summary?.slug) ?? "",
          interactions:
            vouch?.interactions
              ?.map?.((i) => i.interaction_type.localized_string.en_US)
              .join(", ") ?? "",
          created_at: DateTime.fromISO(vouch.created_at),
        }
      : null;
}

function VouchListTable({
  iam,
  vouches: vouchesProp,
}: {
  iam: "author" | "subject";
  vouches: Array<VouchSummary>;
}): React.JSX.Element {
  const [reverse, setReverse] = React.useState(false);
  const [field, setField] = React.useState<keyof VouchSummary>("created_at");
  const vouches = React.useMemo(
    () =>
      vouchesProp.sort((a, b) => (reverse ? -1 : 1) * ((a[field] ?? 0) > (b[field] ?? 0) ? 1 : -1)),
    []
  );
  return (
    <div className={clsx("min-w-0 flex-1")}>
      <H4>
        {match(iam)
          .with("author", () => "Vouches I wrote")
          .with("subject", () => "Vouches about me")
          .exhaustive()}
      </H4>
      <table className="table w-full table-s overflow-hiddenm">
        {/* head */}
        <thead>
          <tr>
            <ColHeader<VouchSummary>
              {...{
                field,
                setField,
                reverse,
                setReverse,
                value: "counterparty_slug",
                label: match(iam)
                  .with("author", () => "Subject")
                  .with("subject", () => "Author")
                  .exhaustive(),
              }}
            />
            <ColHeader<VouchSummary>
              {...{
                field,
                setField,
                reverse,
                setReverse,
                value: "counterparty_gender",
                label: "Gender",
              }}
            />
            <ColHeader<VouchSummary>
              {...{
                field,
                setField,
                reverse,
                setReverse,
                value: "created_at",
              }}
            />
            <ColHeader<VouchSummary>
              {...{
                field,
                setField,
                reverse,
                setReverse,
                value: "interactions",
              }}
            />
            <ColHeader<VouchSummary>
              {...{
                field,
                setField,
                reverse,
                setReverse,
                value: "id",
              }}
            />
          </tr>
        </thead>
        <tbody className={clsx("overflow-y-scroll")}>
          {vouches.map((vouch) => (
            <tr key={vouch.id}>
              <td>
                {vouch.counterparty_slug && (
                  <Link
                    className="whitespace-nowrap"
                    to={adminRoutes.USERS.DETAIL.buildPath({ slug: vouch.counterparty_slug })}
                  >
                    <Avatar tailwindSize="12" slug={vouch.counterparty_slug} />
                    <span className="ml-2 link">{"@" + vouch.counterparty_slug}</span>
                  </Link>
                )}
              </td>
              <td>{vouch.counterparty_gender}</td>
              <td>{vouch.created_at.toLocaleString(DateTime.DATETIME_SHORT)}</td>
              <td>
                <Tooltip>
                  <TooltipContent>{vouch.interactions}</TooltipContent>
                  <TooltipTrigger>
                    <span
                      className={clsx("block overflow-hidden text-ellipsis w-48 whitespace-nowrap")}
                    >
                      {vouch.interactions}
                    </span>
                  </TooltipTrigger>
                </Tooltip>
              </td>
              <td>
                <IdSpan id={vouch.id} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export function AdminVouches(): React.JSX.Element {
  const slug = useTypedParams(adminRoutes.USERS.DETAIL.VOUCHES).slug;
  const { data, loading } = useAdminVouchListQuery({ variables: { slug } });
  const mkAuthorSummary = useMkVouchSummary(true);
  const mkSubjectSummary = useMkVouchSummary(true);
  const writtenItems: Array<VouchSummary> = React.useMemo(
    () => filterNulls(data?.written?.map?.(mkAuthorSummary) ?? []),
    [data, mkSubjectSummary]
  );
  const subjectItems: Array<VouchSummary> = React.useMemo(
    () => filterNulls(data?.about?.map?.(mkSubjectSummary) ?? []),
    [data, mkSubjectSummary]
  );
  return (
    <div className="py-4 flex flex-row justify-stretch gap-2 h-full items-stretch max-h-full overflow-hidden ">
      {loading ? (
        <SpinnerCentered />
      ) : (
        <>
          <div className={clsx("flex-1 ")}>
            <VouchListTable iam="author" vouches={writtenItems} />
          </div>
          <div className={clsx("flex-1 ")}>
            <VouchListTable iam="subject" vouches={subjectItems} />
          </div>
        </>
      )}
    </div>
  );
}
