import { AdminUserAvatar, ColHeader, ColHeaderProps, DateTd, IdSpan } from "../util/widgets";
import {
  Admin_Media_DetailsFragment,
  GqlOps,
  Media_Uploads_Order_By,
  useAdminArchiveMediaMutation,
  useAdminMediaListQuery,
  useAdminProcessMediaMutation,
  useAdminUnarchiveMediaMutation,
  useUpdateMediaMutation,
} from "../__generated__/apollo-hooks";
import { Spinner, SpinnerCentered } from "shared-web-react/dist/widgets/spinner";
import { adminRoutes, useMkImgAdminUrl } from "../util/admin-routes";

import { AdminMediaEditor } from "shared-web-react/dist/widgets/media-editing/media-editor";
import { AuthImage } from "shared-web-react/dist/widgets/authenticated-image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Media_Upload_Statuses_Enum } from "shared/dist/__generated__/components";
import { Paginator } from "shared-web-react/dist/widgets/paginator";
import React from "react";
import { SpinnerButton } from "shared-web-react/dist/widgets/spinner-button";
import clsx from "clsx";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { match } from "ts-pattern";
import { useAdminMediaByIdSuspenseQuery } from "../__generated__/apollo-hooks";
import { useDebounce } from "use-debounce";
import { useTypedParams } from "react-router-typesafe-routes/dom";

export function AdminMediaDetail(): React.JSX.Element {
  return (
    <React.Suspense fallback={<SpinnerCentered className="my-8" />}>
      <AdminMediaDetailInner />
    </React.Suspense>
  );
}

function AdminMediaDetailInner(): React.JSX.Element {
  const mediaId = useTypedParams(adminRoutes.MEDIA.EDIT).media_id;
  return (
    <div className="max-h-full max-w-full flex flex-row gap-2 justify-stretch items-stretch p-4">
      <div className="flex-1 min-w-0 max-h-full">
        <AdminMediaEditor media_id={mediaId} />
      </div>
      <div className="flex-1  h-full">
        <AdminMediaPreview id={mediaId} />
      </div>
    </div>
  );
}

function AdminMediaPreview({ id }: { id: string }) {
  const [size, setSize] = React.useState<"full" | "md" | "lg" | "thumb">("md");
  const { data } = useAdminMediaByIdSuspenseQuery({
    fetchPolicy: "cache-and-network",
    variables: { media_upload_id: id },
  });
  // if (loading) return <SpinnerCentered />;
  const [cacheBreaker, setCacheBreaker] = React.useState(`${Math.floor(Math.random() * 100000)}`);
  const url = useMkImgAdminUrl()(id, size) + `&q=${cacheBreaker}`;
  console.log("🚀 - file: photo-review.tsx:51 - MediaPreview - url:", url);
  return (
    <div className={clsx("flex-col flex items-start justify-start gap-4")}>
      <div className="flex min-w-0 flex-row justify-start items-center gap-2">
        <button
          className={clsx("btn btn-accent")}
          onClick={() => setCacheBreaker(`${Math.floor(Math.random() * 10000)}`)}
        >
          reload
        </button>
        {(["thumb", "md", "lg", "full"] as const).map((s) => (
          <button
            key={s}
            className={clsx(size !== s && "btn-outline", "btn btn-primary")}
            onClick={() => {
              setCacheBreaker(`${Math.floor(Math.random() * 100000)}`);
              setSize(s);
            }}
          >
            {s}
          </button>
        ))}
      </div>
      {url && <img src={url} />}
      <div className={clsx("flex flex-col gap-2 justify-start items-center")}>
        <div>
          Media ID: <IdSpan id={id} />
        </div>
        <div>IS_ON_BIO: {data?.media_uploads_by_pk?.exclude_from_bio ? "NO" : "YES"}</div>
        <div>IS_PRIVATE: {data?.media_uploads_by_pk?.is_private ? "YES" : "NO"}</div>
        <div>IS_NSFW: {data?.media_uploads_by_pk?.is_nsfw ? "YES" : "NO"}</div>
        <div>NSFW Content: {data?.media_uploads_by_pk?.metadata?.nsfw_description}</div>
      </div>
    </div>
  );
}

export function AdminMediaTableHead({
  showOwner,
  ...props
}: { showOwner?: boolean } & Omit<
  ColHeaderProps<Media_Uploads_Order_By>,
  "label" | "value"
>): React.JSX.Element {
  return (
    <thead className="sticky top-0 z-30 bg-base-100">
      <tr>
        <th>image</th>
        <ColHeader {...{ value: "created_at", label: "created at", ...props }} />
        {showOwner && <th>owner</th>}
        <th>status</th>
        <th>archive</th>
        <th>md</th>
        <th>on bio</th>
        <th>nsfw</th>
        <th>private</th>
        <th>caption</th>
        <th>id</th>
      </tr>
    </thead>
  );
}

export type MediaDetails = Admin_Media_DetailsFragment;
function AdminMediaTableRow({
  showOwner,
  mediaItem,
}: {
  mediaItem: MediaDetails;
  showOwner?: boolean;
}): React.JSX.Element {
  const url = useMkImgAdminUrl()(mediaItem.id, "thumb");
  if (!url) return <div>missing image</div>;
  const archived = mediaItem.status === Media_Upload_Statuses_Enum.V1Archived;
  try {
    return (
      <tr className={clsx({ "bg-neutral-200 text-black": archived })}>
        <td>
          {mediaItem && mediaItem.id && (
            <Link to={adminRoutes.MEDIA.EDIT.buildPath({ media_id: mediaItem.id })}>
              <div className="h-32 w-32 max-h-32 max-w-32">
                <AuthImage src={url} />
              </div>
            </Link>
          )}
        </td>
        <DateTd date={mediaItem.created_at} />
        {showOwner && (
          <td>
            <AdminUserAvatar slug={mediaItem.owner?.url_slug?.slug} />
          </td>
        )}
        <td>
          <ImageActions img={mediaItem} />
        </td>
        <td>
          <ArchiveImage img={mediaItem} />
        </td>
        <td>
          {mediaItem.metadata ? (
            <div>
              WxH: {Math.round(mediaItem.metadata.width * mediaItem.metadata.crop_width)}x
              {Math.round(mediaItem.metadata.height * mediaItem.metadata.crop_height)}
            </div>
          ) : (
            `missing MD`
          )}
        </td>
        <td>{mediaItem.exclude_from_bio ? "no" : "yes"}</td>
        <td>
          <ImageToggle img={mediaItem} field="is_nsfw" />
        </td>
        <td>
          <ImageToggle img={mediaItem} field="is_private" />
        </td>
        <td>{mediaItem.caption ?? ""}</td>
        <td>
          {mediaItem && mediaItem.id && (
            <Link to={adminRoutes.MEDIA.EDIT.buildPath({ media_id: mediaItem.id })}>
              <IdSpan id={mediaItem.id} />
            </Link>
          )}
        </td>
      </tr>
    );
  } catch (e) {
    return <div>Error: {e?.toString?.() ?? ""}</div>;
  }
}
function ImageToggle({
  img,
  field,
  loading: loadingExternal,
}: {
  field: "is_nsfw" | "is_private";
  loading?: boolean;
  img: undefined | MediaDetails;
}): React.JSX.Element {
  const [updateMediaUpload] = useUpdateMediaMutation();
  const [loading, setLoading] = React.useState(false);
  const value = img?.[field];
  console.log("🚀 ~ file: user-detail.tsx:33 ~ value:", value, field);
  const wrappedChange = async () => {
    if (!img) {
      return;
    }
    setLoading(true);
    try {
      if (!img.id) return;
      const vars = {
        variables: { media_id: img.id, update: { [field]: !value } },
      };
      console.log("🚀 ~ file: user-detail.tsx:42 ~ wrappedChange ~ vars:", vars);
      await updateMediaUpload(vars);
    } finally {
      setLoading(false);
    }
  };
  if (!img) return <></>;
  if (loading) return <Spinner />;
  return (
    <input
      disabled={loadingExternal || loading}
      type="checkbox"
      checked={value ?? false}
      onChange={wrappedChange}
      className="checkbox"
    />
  );
}

export function AdminMediaTable({
  showOwner,
  mediaItems,
  ...props
}: { showOwner?: boolean; mediaItems?: null | Array<MediaDetails> } & Omit<
  ColHeaderProps<Media_Uploads_Order_By>,
  "label" | "value"
>) {
  return (
    <table className="table w-full max-w-full min-w-min table-sm ">
      {/* head */}
      <AdminMediaTableHead showOwner={showOwner} {...props} />
      <tbody>
        {(mediaItems ?? []).map((mediaItem) => (
          <AdminMediaTableRow showOwner={showOwner} {...{ mediaItem }} key={mediaItem.id} />
        ))}
      </tbody>
    </table>
  );
}

export function AdminMediaList({}): React.JSX.Element {
  const limit = 20;
  const [missingMetadataOnly, setMissingMetadataOnly] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [field, setField] = React.useState<keyof Media_Uploads_Order_By>("created_at");
  const [filter, setFilter] = React.useState("");
  const [reverse, setReverse] = React.useState(false);
  const [debouncedFilter] = useDebounce(filter, 1000);
  const { data, loading } = useAdminMediaListQuery({
    variables: {
      limit,
      offset: (page - 1) * limit,
      md_filter: missingMetadataOnly ? { height: { _is_null: true } } : undefined,
      ci_filter: `%${debouncedFilter}%`,
      str_filter: `%${debouncedFilter}%`,
      order_by: { [field]: reverse ? "desc" : "asc" },
    },
  });

  const count = data?.count?.aggregate?.count ?? 0;
  const pageCount = count ? Math.ceil(count / limit) : 0;
  return (
    <div className="space-y-2 pt-4 flex flex-col justify-stretch max-h-screen overflow-hidden relative">
      <div className="flex flex-row justify-start items-center gap-2">
        <Paginator {...{ page, setPage, pageCount }} />
        <div className="form-control">
          <div className="join">
            <input
              type="text"
              placeholder="Search…"
              className="UserList join-item input input-bordered"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
            <button onClick={() => setFilter("")} className="join-item btn">
              reset
            </button>
          </div>
        </div>
        <label className={clsx("btn-primary btn", missingMetadataOnly ? "" : "btn-outline")}>
          <input
            type="checkbox"
            checked={missingMetadataOnly}
            onChange={() => setMissingMetadataOnly(!missingMetadataOnly)}
          />
          Only With Missing MD
        </label>
        Total: {count}
        {loading && <Spinner />}
      </div>
      <div className={clsx("relative max-h-full overflow-auto")}>
        <AdminMediaTable
          {...{
            field,
            setField,
            reverse,
            setReverse,
            showOwner: true,
            mediaItems: data?.media_uploads ?? [],
          }}
        />
      </div>
    </div>
  );
}

const Statuses = Media_Upload_Statuses_Enum;
function ImageActions({ img }: { img: MediaDetails }): React.JSX.Element {
  const [mutate] = useAdminProcessMediaMutation();
  return match(img.status)
    .with(Statuses.V1Archived, () => <span className={clsx()}>archived</span>)
    .with(Statuses.V1Processed, Statuses.V1Uploaded, () => (
      <SpinnerButton
        className={clsx("btn btn-xs btn-neutral")}
        onClick={() =>
          mutate({
            awaitRefetchQueries: true,
            refetchQueries: [GqlOps.Query.AdminMediaForUser],
            variables: { media_upload_id: img.id },
          })
        }
      >
        process
      </SpinnerButton>
    ))
    .with(Statuses.V1Cancelled, Statuses.V1New, () => <span>cancelled</span>)
    .with(Statuses.V2Error, () => <span>an error occurred, please try again</span>)
    .with(Statuses.V2Processed, () => (
      <span className={clsx("whitespace-nowrap")}>
        <FontAwesomeIcon icon={faCheckCircle} fixedWidth />
        ready
        <SpinnerButton
          className={clsx("btn btn-xs btn-neutral mx-2")}
          onClick={() =>
            mutate({
              awaitRefetchQueries: true,
              refetchQueries: [GqlOps.Query.AdminMediaForUser],
              variables: { media_upload_id: img.id },
            })
          }
        >
          force reprocess
        </SpinnerButton>
      </span>
    ))
    .exhaustive();
}

function ArchiveImage({ img }: { img: MediaDetails }): React.JSX.Element {
  const [archiveMutation] = useAdminArchiveMediaMutation();
  const [unArchiveMutation] = useAdminUnarchiveMediaMutation();
  return match(img.status)
    .with(Statuses.V1Archived, () => (
      <span>
        <SpinnerButton
          className={clsx("btn btn-xs btn-primary-content btn-outline mx-2")}
          onClick={() =>
            unArchiveMutation({
              awaitRefetchQueries: true,
              refetchQueries: [GqlOps.Query.AdminMediaForUser],
              variables: { media_upload_id: img.id },
            })
          }
        >
          Unarchive Image
        </SpinnerButton>
      </span>
    ))
    .with(Statuses.V1Processed, Statuses.V1Uploaded, () => <></>)
    .with(Statuses.V1Cancelled, Statuses.V1New, () => <></>)
    .with(Statuses.V2Error, () => <></>)
    .with(Statuses.V2Processed, () => (
      <span>
        <SpinnerButton
          className={clsx("btn btn-xs btn-warning mx-2")}
          onClick={() =>
            archiveMutation({
              awaitRefetchQueries: true,
              refetchQueries: [GqlOps.Query.AdminMediaForUser],
              variables: { media_upload_id: img.id },
            })
          }
        >
          Archive Image
        </SpinnerButton>
      </span>
    ))
    .exhaustive();
}
