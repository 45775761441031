import {
  AdminOnboardingStatusToggle,
  AdminUserAvatar,
  AdminVerificationToggle,
  ColHeader,
  IdSpan,
} from "../util/widgets";
import {
  Admin_User_Summaries_Order_By,
  GqlOps,
  useAdminMigrateLegacyLocationMutation,
  useAdminUserListQuery,
} from "../__generated__/apollo-hooks";
import { Spinner, SpinnerCentered } from "shared-web-react/dist/widgets/spinner";

import { DateTime } from "luxon";
import { GenderPicker } from "shared-web-react/dist/widgets/gender-picker";
import { Link } from "react-router-dom";
import { Onboarding_Statuses_Enum } from "shared/dist/__generated__/components";
import { Paginator } from "shared-web-react/dist/widgets/paginator";
import React from "react";
import { UserIdSlugSn } from "../util/types";
import { adminRoutes } from "../util/admin-routes";
import clsx from "clsx";
import { typedFromEntries } from "shared/dist/util";
import { useDebounce } from "use-debounce";

export function UserList(): React.JSX.Element {
  const limit = 50;
  const [page, setPage] = React.useState(1);
  const [field, setField] = React.useState<keyof Admin_User_Summaries_Order_By>("created_at");
  const [filter, setFilter] = React.useState("");
  const [reverse, setReverse] = React.useState(true);
  const [migrateLegacy] = useAdminMigrateLegacyLocationMutation();
  const [debouncedFilter, { isPending }] = useDebounce(filter, 1000);
  const [selectedUsers, setSelectedUsers] = React.useState<Record<string, UserIdSlugSn>>({});
  const [showObStatus, setShowObStatus] = React.useState<Onboarding_Statuses_Enum | null>(null);
  const { data, loading } = useAdminUserListQuery({
    variables: {
      limit,
      offset: (page - 1) * limit,
      filter: `%${debouncedFilter}%`,
      // filter: `%${debouncedFilter?.replace?.(/@/g, "")}%`,
      loc_filter: `%${debouncedFilter}%`,
      order_by: { [field]: reverse ? "desc" : "asc" },
      excluded_statuses: showObStatus
        ? Object.values(Onboarding_Statuses_Enum).filter((k) => k !== showObStatus)
        : [],
    },
  });
  if (loading) return <SpinnerCentered />;

  const count = data?.admin_user_summaries_aggregate?.aggregate?.count ?? 0;
  const pageCount = count ? Math.ceil(count / limit) : 0;
  return (
    <div className="space-y-2 pt-4 flex flex-col justify-stretch max-h-screen overflow-hidden relative">
      <div className="flex flex-row justify-start items-center gap-2">
        Total: {data?.admin_user_summaries_aggregate?.aggregate?.count ?? 0}
        <Paginator {...{ page, setPage, pageCount }} />
        <div className="form-control">
          <div className="join">
            <input
              type="text"
              placeholder="Search…"
              className="UserList join-item input input-bordered"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
            <button onClick={() => setFilter("")} className="join-item btn">
              reset
            </button>
          </div>
        </div>
        <div className={"join"}>
          <button
            onClick={() => setShowObStatus(null)}
            className={clsx("join-item btn ", !showObStatus ? "btn-primary" : "")}
          >
            All Users
          </button>
          <button
            onClick={() => setShowObStatus(Onboarding_Statuses_Enum.V2_00New)}
            className={clsx(
              "join-item btn ",
              showObStatus === Onboarding_Statuses_Enum.V2_00New ? "btn-primary" : ""
            )}
          >
            New
          </button>
          <button
            onClick={() => setShowObStatus(Onboarding_Statuses_Enum.V2_10ObCompletePendingApproval)}
            className={clsx(
              "join-item btn ",
              showObStatus === Onboarding_Statuses_Enum.V2_10ObCompletePendingApproval
                ? "btn-primary"
                : ""
            )}
          >
            Pending
          </button>
          <button
            onClick={() => setShowObStatus(Onboarding_Statuses_Enum.V2_20LiveUser)}
            className={clsx(
              "join-item btn ",
              showObStatus === Onboarding_Statuses_Enum.V2_20LiveUser ? "btn-primary" : ""
            )}
          >
            Live
          </button>
        </div>
        {Object.keys(selectedUsers).length > 0 && (
          <Link
            to={adminRoutes.MESSAGE_BLAST.buildPath({})}
            state={adminRoutes.MESSAGE_BLAST.buildState({
              preloadUsers: Object.values(selectedUsers),
            })}
            className="btn"
          >
            Message {Object.keys(selectedUsers).length} user(s)
          </Link>
        )}
        <span>{count}</span>
        {(loading || isPending()) && <Spinner />}
      </div>
      <div className="overflow-auto w-full ">
        <table className="table w-full max-w-full min-w-min overflow-auto table-sm ">
          {/* head */}
          <thead className="sticky top-0 z-30 bg-base-100">
            <tr>
              <th>
                <input
                  className="checkbox"
                  type="checkbox"
                  disabled={count === 0}
                  checked={count > 0 && Object.keys(selectedUsers).length === count}
                  onChange={() =>
                    setSelectedUsers(
                      Object.keys(selectedUsers).length === count
                        ? {}
                        : typedFromEntries<string, UserIdSlugSn>(
                            data?.admin_user_summaries?.map((user) =>
                              user.id && user.screen_name && user.slug
                                ? [
                                    user.id,
                                    { id: user.id, screenName: user.screen_name, slug: user.slug },
                                  ]
                                : null
                            )
                          )
                    )
                  }
                />
              </th>
              <th>username</th>
              <th>verified</th>
              <ColHeader
                {...{
                  field,
                  setField,
                  reverse,
                  setReverse,
                  value: "onboarding_status",
                  label: "Approval Status",
                }}
              />
              <ColHeader
                {...{
                  field,
                  setField,
                  reverse,
                  setReverse,
                  label: "gender",
                  value: "gender_id",
                }}
              />
              <ColHeader
                {...{
                  field,
                  setField,
                  reverse,
                  setReverse,
                  value: "phone_number",
                }}
              />
              <ColHeader
                {...{
                  field,
                  setField,
                  reverse,
                  setReverse,
                  value: "email",
                }}
              />
              <th>screen_name</th>
              <th>group</th>
              <th>Location</th>
              <ColHeader
                {...{
                  field,
                  setField,
                  reverse,
                  setReverse,
                  value: "created_at",
                }}
              />
              <ColHeader
                {...{
                  field,
                  setField,
                  reverse,
                  setReverse,
                  value: "id",
                }}
              />
              <ColHeader
                {...{
                  field,
                  setField,
                  reverse,
                  setReverse,
                  value: "deleted_at",
                }}
              />
            </tr>
          </thead>
          <tbody>
            {data?.admin_user_summaries?.map((user, idx) => (
              <tr key={idx}>
                <td>
                  {user && user.id && (
                    <input
                      type="checkbox"
                      className="checkbox"
                      onChange={() => {
                        setSelectedUsers((prev) =>
                          !user.id || !user.slug || !user.screen_name // unnecesary but here for TSCs happiness
                            ? prev
                            : selectedUsers[user.id]
                            ? typedFromEntries(Object.entries(prev).filter(([k]) => k !== user.id))
                            : {
                                ...prev,
                                [user.id]: {
                                  id: user.id,
                                  screenName: user.screen_name,
                                  slug: user.slug,
                                },
                              }
                        );
                      }}
                      checked={!!selectedUsers[user.id]}
                    />
                  )}
                </td>
                <td className={clsx(user.deleted_at && "line-through")}>
                  <AdminUserAvatar slug={user.slug} />
                </td>
                <td className={clsx(user.deleted_at && "line-through")}>
                  {user.id && (
                    <AdminVerificationToggle
                      user_id={user.id}
                      source={user.identity_verifications?.[0]?.source}
                      status={user.identity_verifications?.[0]?.success}
                    />
                  )}
                </td>
                <td>{user.id && <AdminOnboardingStatusToggle user_id={user.id!} />}</td>
                <td className={clsx(user.deleted_at && "line-through")}>
                  <GenderPicker
                    size="sm"
                    placeholder="- NULL -"
                    forUserId={user.id}
                    labelClassName="hidden"
                    isAdmin
                    hideDescriptions
                  />
                  {/* {user.gender_id} */}
                </td>
                <td
                  className={clsx(
                    "whitespace-nowrap text-ellipsis min-w-[8ch]",
                    user.deleted_at && "line-through"
                  )}
                >
                  {`+${user.itu_country_code}-${user.phone_number}`}
                </td>
                <td
                  className={clsx(
                    "whitespace-nowrap text-ellipsis min-w-[8ch]",
                    user.deleted_at && "line-through"
                  )}
                >
                  {user.email}
                </td>
                <td
                  className={clsx(
                    "whitespace-nowrap text-ellipsis min-w-[8ch]",
                    user.deleted_at && "line-through"
                  )}
                >
                  {user.screen_name}
                </td>
                <td className={clsx("whitespace-nowrap text-ellipsis min-w-[8ch] text-center")}>
                  {user.groupings && user.groupings?.length
                    ? (
                        user.groupings.map((g) => g.value).reduce((a, b) => a + b, 0) /
                        user.groupings.length
                      )?.toFixed?.(3)
                    : user.slug && (
                        <Link
                          className={clsx("btn btn-xs")}
                          to={adminRoutes.PROFILE_GROUPING.DETAIL.buildPath({ slug: user.slug })}
                        >
                          set
                        </Link>
                      )}
                </td>
                <td className={clsx("whitespace-nowrap text-ellipsis min-w-[8ch]")}>
                  {!user.location_gps?.description &&
                    !user.location_manual?.description &&
                    user.id &&
                    user.legacy_location && (
                      <button
                        onClick={() =>
                          migrateLegacy({
                            variables: { user_id: user.id! },
                            refetchQueries: [GqlOps.Query.AdminUserList],
                          })
                        }
                        className={clsx("btn btn-xs bg-red-600")}
                      >
                        legacy {user.location_description}
                      </button>
                    )}
                  {user.location_gps?.description && (
                    <div>gps: {user?.location_gps?.description}</div>
                  )}
                  {user.location_manual?.description && (
                    <div>manual: {user?.location_manual?.description}</div>
                  )}
                </td>
                <td className={clsx("whitespace-nowrap text-ellipsis min-w-[8ch]")}>
                  {user.created_at
                    ? DateTime.fromISO(user.created_at).toLocaleString(DateTime.DATETIME_SHORT)
                    : null}
                </td>
                <td className="!no-underline">
                  {user.deleted_at
                    ? DateTime.fromISO(user.deleted_at).toLocaleString(DateTime.DATETIME_SHORT)
                    : null}
                </td>
                <td>
                  <IdSpan id={user.id} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
