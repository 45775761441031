import {
  AdminDiscoveryDebuggerQueryVariables,
  Discovery_SuccessFragment,
  useAdminDiscoveryDebuggerLazyQuery,
  useAdminDiscoveryDebuggerQuery,
} from "../../__generated__/apollo-hooks";
import { NavLink, Outlet } from "react-router-dom";
import { P, match } from "ts-pattern";
import {
  SelectWithDetails,
  SelectWithDetailsOption,
} from "shared-web-react/dist/widgets/select-details";
import { classNames, sortBy, uniqWith } from "shared/dist/util";
import {
  convert,
  distToLocale,
  distanceOptionsKeyedInMeters,
} from "shared-web-react/dist/util/units";

import { Avatar } from "shared-web-react/dist/widgets/avatar";
import { DateTime } from "luxon";
import { H3 } from "shared-web-react/dist/widgets/text";
import { Link } from "react-router-dom";
import React from "react";
import { Spinner } from "shared-web-react/dist/widgets/spinner";
import { adminRoutes } from "../../util/admin-routes";
import clsx from "clsx";
import { exampleLocations } from "shared/dist/util/loc";
import { useDebounce } from "use-debounce";
import { useGenderLookup } from "shared-web-react/dist/helpers/gender";

const locations = Object.entries(exampleLocations).map(
  ([name, { lat, long }]) =>
    [name, `${name} (${lat.toFixed(4)},${long.toFixed(4)})`] as SelectWithDetailsOption
);

function MultipleChoiceToggle<T>({
  thisChoice,
  choices,
  label,
  setChoices,
}: {
  thisChoice: T;
  label?: string;
  choices: Array<T>;
  setChoices: React.Dispatch<React.SetStateAction<Array<T>>>;
}): React.JSX.Element {
  const selected = choices.includes(thisChoice);
  return (
    <button
      className={classNames("btn-sm btn ", selected ? "btn-primary" : "btn-neutral")}
      onClick={() =>
        selected
          ? setChoices((c) => c.filter((inner: T) => inner !== thisChoice))
          : setChoices(uniqWith([thisChoice, ...choices], (a, b) => a === b))
      }
    >
      {label ?? String(thisChoice)}
    </button>
  );
}

const dummyUserId = "99999999-8888-7777-6666-555555555555";

export function AdminDiscoveryDebuggerDetailQuery(): React.JSX.Element {
  const [minAge, setMinAge] = React.useState(21);
  const [maxAge, setMaxAge] = React.useState(70);
  const [distance, setDistance] = React.useState<number>(
    Number(distanceOptionsKeyedInMeters[0][0])
  );
  // const [page, setPage] = React.useState(0);
  const page = 0; // TODO later
  const [lat, setLat] = React.useState(33.9);
  const [selectedLocation, setSelectedLocation] = React.useState(locations[0]);
  const [long, setLong] = React.useState(-118.166);
  const [genders, setGenders] = React.useState<Array<string>>([]);
  const genderLookup = useGenderLookup()?.data;
  const [showAllGenders, setShowAllGenders] = React.useState(false);
  const [gendersDebounced] = useDebounce(genders, 500);
  const [minAgeDebounced] = useDebounce(minAge, 500);
  const [includeUnverifiedUsers, setIncludeUnverifiedUsers] = React.useState(false);
  const [maxAgeDebounced] = useDebounce(maxAge, 500);
  const [debouncePending, setDebouncePending] = React.useState(false);
  const genderList = genderLookup
    ? sortBy(
        Object.values(genderLookup).filter((g) => g.seq_no > (showAllGenders ? 1 : 810)),
        (a) => a.seq_no
      )
        .map((g) => g.id)
        .reverse()
    : [];
  const variables: AdminDiscoveryDebuggerQueryVariables = React.useMemo(
    () => ({
      override_user_id: dummyUserId,
      page,
      distance_m: distance,
      lat,
      long,
      include_unverified_users: includeUnverifiedUsers,
      max_b_day: DateTime.now().minus({ years: minAgeDebounced }).toISODate(),
      min_b_day: DateTime.now().minus({ years: maxAgeDebounced }).toISODate(),
      single_genders: gendersDebounced,
      couple_genders: gendersDebounced.flatMap((outer) =>
        gendersDebounced.filter((inner) => inner <= outer).map((inner) => `${inner}+${outer}`)
      ),
    }),
    [
      page,
      distance,
      includeUnverifiedUsers,
      lat,
      long,
      minAgeDebounced,
      maxAgeDebounced,
      gendersDebounced,
    ]
  );
  const skip = !genders.length;
  const { data, loading } = useAdminDiscoveryDebuggerQuery({ skip, variables });
  React.useEffect(() => { setDebouncePending(true); }, [minAge, maxAge, genders]); //prettier-ignore
  React.useEffect(() => { setDebouncePending(false); }, [loading]); //prettier-ignore
  const anyLoading = debouncePending;
  const items: Discovery_SuccessFragment["items"] =
    data?.l_discovery_override_prefs?.__typename === "L_Discovery_Response_Success"
      ? data?.l_discovery_override_prefs?.items
      : [];

  return (
    <div className="AdminDiscoveryDebugger">
      <div className={classNames("space-y-2")}>
        <div className={classNames("flex flex-row justify-start gap-2 py-2")}>
          <label className="label text-left justify-start">
            <span className="mr-1 label-text">Include Unverified?</span>
            <input
              type="checkbox"
              checked={includeUnverifiedUsers}
              onChange={() => setIncludeUnverifiedUsers(!includeUnverifiedUsers)}
              className="checkbox"
            />
          </label>
          <SelectWithDetails
            size="sm"
            loading={anyLoading}
            disableOnLoading
            options={distanceOptionsKeyedInMeters}
            inputClassName="border-2"
            selected={distance}
            setSelected={function (value: SelectWithDetailsOption | null): void {
              value?.[0] && setDistance(Number(value?.[0]));
            }}
          />
          <SelectWithDetails
            size="sm"
            loading={anyLoading}
            inputClassName="border-2"
            disableOnLoading
            options={locations}
            selected={selectedLocation[0]}
            setSelected={function (value: SelectWithDetailsOption | null): void {
              if (!value?.[0]) { return; } // prettier-ignore
              setSelectedLocation(value);
              const coords = exampleLocations[value[0] as keyof typeof exampleLocations];
              if (!coords) { return; } // prettier-ignore
              setLat(coords.lat);
              setLong(coords.long);
            }}
          />
        </div>
        <div className="max-w-[70rem] overflow-x-scroll flex items-center flex-row flex-nowrap gap-1">
          <label className="label text-left justify-start">
            <span className="mr-1 label-text">Show All Genders?</span>
            <input
              type="checkbox"
              checked={showAllGenders}
              onChange={() => setShowAllGenders(!showAllGenders)}
              className="checkbox"
            />
          </label>
          {genderList.map((g) => (
            <MultipleChoiceToggle
              key={g}
              thisChoice={g}
              choices={genders}
              setChoices={setGenders}
            />
          ))}
        </div>
        <div>
          <span>
            Age Range:
            <input
              type="text"
              inputMode="numeric"
              className="w-16 mx-1 input input-sm input-secondary"
              value={minAge}
              min={minAge + 1}
              max={99}
              onChange={(e) => setMaxAge(Number(e.target.value))}
              pattern="\d*"
            />
            -
            <input
              type="text"
              inputMode="numeric"
              value={maxAge}
              className="w-16 input mx-1 input-sm input-secondary"
              min={18}
              max={maxAge - 1}
              onChange={(e) => setMinAge(Number(e.target.value))}
              pattern="\d*"
            />
          </span>
        </div>
      </div>
      <div>
        {/* <Paginator {...{ page, pageCount, setPage }} /> */}
        {items.length} matches
        <table className={classNames("table table-sm")}>
          <thead>
            <tr>
              <th>distance</th>
              <th>match</th>
              <th>age</th>
              <th>gender</th>
              <th>parnter's match</th>
              <th>parnter's age</th>
              <th>parnter's gender</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, idx) => (
              <tr key={idx}>
                <td>{distToLocale({ dist: item.user.distance_m, units: "m" })}</td>
                <td>
                  <Link
                    to={adminRoutes.USERS.DETAIL.buildPath({
                      slug: item.user.user_slug,
                    })}
                  >
                    <Avatar tailwindSize="8" slug={item.user.user_slug} />
                    {" @" + item.user.user_slug}
                  </Link>
                </td>
                <td>{item.user.age}</td>
                <td>{item.user.gender_id.replace("gender_", "")}</td>
                <td>
                  {item.partner && (
                    <Link
                      to={adminRoutes.USERS.DETAIL.buildPath({
                        slug: item.partner.user_slug,
                      })}
                    >
                      <Avatar tailwindSize="8" slug={item.partner.user_slug} />
                      {" @" + item.partner.user_slug}
                    </Link>
                  )}
                </td>
                <td>{item.partner?.age}</td>
                <td>{item.partner?.gender_id?.replace?.("gender_", "")}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export function AdminDiscoveryDebugger(): React.JSX.Element {
  return (
    <div>
      <H3>Discovery Debugging</H3>

      <div className={clsx("flex-0 tabs tabs-lifted tabs-lg")}>
        <NavLink
          end
          className={({ isActive }) => classNames("tab", isActive && "tab-active")}
          to={adminRoutes.DISCOVERY_STATS.DEBUGGER.buildPath({})}
        >
          Counts
        </NavLink>
        <NavLink
          className={({ isActive }) => classNames("tab", isActive && "tab-active")}
          to={adminRoutes.DISCOVERY_STATS.DEBUGGER.DETAIL_QUERY.buildPath({})}
        >
          Detail
        </NavLink>
      </div>
      <Outlet />
    </div>
  );
}

export function AdminDiscoveryCounts(): React.JSX.Element {
  return (
    <div className={clsx("space-y-8 pt-4")}>
      <div>
        <div>verified users only</div>
        <AdminDiscoveryCountsInner includeUnverifiedUsers={false} />
      </div>
      <div>
        <div>verified and unverified users</div>
        <AdminDiscoveryCountsInner includeUnverifiedUsers={true} />
      </div>
    </div>
  );
}
function AdminDiscoveryCountsInner({
  includeUnverifiedUsers,
}: {
  includeUnverifiedUsers: boolean;
}): React.JSX.Element {
  const topLocations = {
    lax: exampleLocations.lax,
    pasadena: exampleLocations.pasadena,
    miami: exampleLocations.brickell,
  }; // , 'ny': exampleLocations.ny, 'hoboken': exampleLocations.hoboken, 'brooklyn': exampleLocations.brooklyn};
  const [showAllGenders] = React.useState(false);
  // const [showAllGenders, _setShowAllGenders] = React.useState(false);
  const genderLookup = useGenderLookup()?.data;
  const genderList = React.useMemo(
    () =>
      genderLookup
        ? sortBy(
            Object.values(genderLookup).filter((g) => g.seq_no > (showAllGenders ? 1 : 810)),
            (a) => a.seq_no
          )
            .map((g) => g.id)
            .reverse()
        : [],
    [genderLookup, showAllGenders]
  );
  // console.log("🚀 - file: debugger.tsx:311 - genderList:", genderList);
  // const pairedGenders = React.useMemo(
  //   () => genderList.flatMap((g1) => genderList.filter((g2) => g1 > g2).map((g2) => `${g1}+${g2}`)),
  //   [genderList]
  // );
  const distances = [
    convert({ round: true, from: "mi", to: "m", dist: 1 }),
    convert({ round: true, from: "mi", to: "m", dist: 5 }),
    convert({ round: true, from: "mi", to: "m", dist: 25 }),
    convert({ round: true, from: "mi", to: "m", dist: 100 }),
  ];
  return (
    <div className={clsx("relative")}>
      <div className={clsx("max-w-full overflow-auto")}>
        <table className="table w-full border border-base-300 table-sm !text-center">
          <thead>
            <tr>
              <th>city</th>
              {Object.entries(topLocations).map((city, idx) => (
                <th
                  className={clsx("text-center border-x border-x-base-300")}
                  colSpan={distances.length}
                  key={idx}
                >
                  {city[0]}
                </th>
              ))}
            </tr>
            <tr className="border-b-2 border-b-base-300">
              <th>dist</th>
              {Object.entries(topLocations).flatMap((_, idx) =>
                distances.map((dist, distIdx) => (
                  <th
                    className={clsx(!distIdx && "border-l border-l-base-300")}
                    key={`${idx}-${distIdx}`}
                  >
                    {distToLocale({ dist, units: "m" })}
                  </th>
                ))
              )}
            </tr>
          </thead>
          <tbody>
            {[...genderList, "gender_woman+gender_man", "gender_man+gender_woman"].map(
              (gender_id, rowIdx) => (
                <tr key={gender_id}>
                  <td>{gender_id.replace(/gender_/g, "")}</td>
                  {Object.entries(topLocations).flatMap(([, coords], cityIdx) =>
                    distances.map((dist, distIdx) => (
                      <CountsTableCell
                        className={clsx(!distIdx && "border-l border-l-base-300")}
                        key={`${gender_id}-${distIdx}-${cityIdx}`}
                        gender_id={gender_id}
                        includeUnverifiedUsers={includeUnverifiedUsers}
                        lat={coords.lat}
                        long={coords.long}
                        dist={dist}
                        delayMs={
                          50 *
                          (distIdx +
                            distances.length * cityIdx +
                            rowIdx * distances.length * Object.keys(topLocations).length)
                        }
                      />
                    ))
                  )}
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

function CountsTableCell({
  className,
  delayMs,
  dist,
  gender_id,
  includeUnverifiedUsers,
  lat,
  long,
}: {
  className?: string;
  delayMs: number;
  dist: number;
  gender_id: string;
  includeUnverifiedUsers: boolean;
  lat: number;
  long: number;
}): React.JSX.Element {
  const isPair = gender_id.includes("+");
  const [query, { data, loading }] = useAdminDiscoveryDebuggerLazyQuery();
  React.useEffect(() => {
    const tm = setTimeout(
      () =>
        query({
          variables: {
            lat,
            long,
            override_user_id: dummyUserId,
            distance_m: dist,
            max_b_day: DateTime.now().minus({ years: 18 }).toISODate(),
            min_b_day: "1900-01-01",
            couple_genders: isPair ? [gender_id] : [],
            single_genders: isPair ? [] : [gender_id],
            include_unverified_users: includeUnverifiedUsers,
            page: 0,
          },
        }),
      delayMs
    );
    return () => clearTimeout(tm);
  }, [delayMs, dist, gender_id, includeUnverifiedUsers, isPair, lat, long, query]);
  if (!loading && !data?.l_discovery_override_prefs) return <td>-</td>;
  if (loading)
    return (
      <td>
        <Spinner size="sm" />
      </td>
    );

  const count = isPair
    ? match(data?.l_discovery_override_prefs)
        .with({ couple_matches: P.select() }, (s) => s)
        .otherwise(() => null)
    : match(data?.l_discovery_override_prefs)
        .with({ individual_matches: P.select() }, (s) => s)
        .otherwise(() => null);
  return <td className={clsx("text-center", className)}>{count}</td>;
}
