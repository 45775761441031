import { Spinner, SpinnerCentered } from "shared-web-react/dist/widgets/spinner";

import { Avatar } from "shared-web-react/dist/widgets/avatar";
import { DateTime } from "luxon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Paginator } from "shared-web-react/dist/widgets/paginator";
import React from "react";
import { adminRoutes } from "../util/admin-routes";
import clsx from "clsx";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { useAdminNotificationsQuery } from "../__generated__/apollo-hooks";
import { useDebounce } from "use-debounce";

export function AdminNotifications(): React.JSX.Element {
  const limit = 25;
  const [page, setPage] = React.useState(1);
  const [filter, setFilter] = React.useState("");
  const [debouncedFilter, { isPending }] = useDebounce(filter, 1000);
  const { data, loading, refetch } = useAdminNotificationsQuery({
    pollInterval: 60 * 1000,
    variables: {
      limit,
      offset: (page - 1) * limit,
      filter: `%${debouncedFilter}%`,
      loc_filter: `%${debouncedFilter}%`,
    },
  });
  if (loading) {
    return <SpinnerCentered />;
  }
  const totalCount = data?.total?.aggregate?.count ?? 0;
  const filteredCount = data?.filtered_aggregate?.aggregate?.count ?? 0;
  const pageCount = Math.ceil(filteredCount / limit);
  return (
    <div className="space-y-2 py-4 flex flex-col justify-stretch max-h-screen overflow-hidden relative">
      <div className="flex flex-row justify-start items-center gap-2">
        {filteredCount < totalCount ? (
          <span>
            Showing {filteredCount} of {totalCount} total
          </span>
        ) : (
          <span> Total: {totalCount}</span>
        )}
        <Paginator {...{ page, setPage, pageCount }} />
        <div className="form-control">
          <div className="join">
            <input
              type="text"
              placeholder="Search…"
              className="join-item input input-bordered"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
            <button onClick={() => setFilter("")} className="join-item btn">
              reset
            </button>
            <button onClick={() => refetch()} className="join-item btn">
              reload
            </button>
          </div>
        </div>
        {(loading || isPending()) && <Spinner />}
      </div>
      <div className="overflow-auto w-full ">
        <table className="table w-full table-sm">
          {/* head */}
          <thead>
            <tr>
              <th>To</th>
              <th>Push?</th>
              <th>Email?</th>
              <th>Sent</th>
              <th>trigger</th>
              <th>source?</th>
              <th>emails</th>
            </tr>
          </thead>
          <tbody>
            {data?.notification_logs?.map((log, idx) => (
              <tr key={idx}>
                <td className={clsx("whitespace-nowrap")}>
                  {log.admin_user_summary?.slug && (
                    <Link
                      to={adminRoutes.USERS.DETAIL.buildPath({
                        slug: log.admin_user_summary.slug,
                      })}
                    >
                      <Avatar tailwindSize="12" slug={log.admin_user_summary.slug} />
                      {log.admin_user_summary?.screen_name && (
                        <span className={clsx("ml-1 italic")}>
                          {log.admin_user_summary?.screen_name}
                        </span>
                      )}
                    </Link>
                  )}
                </td>
                <td>{log.push && <FontAwesomeIcon icon={faCheck} fixedWidth />}</td>
                <td>{log.email && <FontAwesomeIcon icon={faCheck} fixedWidth />}</td>
                <td>{log.trigger}</td>
                <td className={clsx("whitespace-nowrap")}>
                  {DateTime.fromISO(log.created_at).toLocaleString(
                    DateTime.DATETIME_SHORT_WITH_SECONDS
                  )}
                </td>
                <td>{log.source}</td>
                <td>
                  <a
                    className={clsx("link text-xs")}
                    href={log.presigned_url?.presigned_url}
                    target="_blank"
                  >
                    {log.s3_key}
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
