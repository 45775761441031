import {
  AdminOnboardingStatusToggle,
  AdminTab,
  AdminVerificationToggle,
  IdSpan,
  fmtDt,
} from "../util/widgets";
import { EnumSelect, EnumSelectWithDesc } from "shared-web-react/dist/widgets/enum-select";
import {
  Face_Privacy_Levels_Enum,
  L_Notification_Test_Trigger,
  Profile_Privacy_Levels_Enum,
  useAdminGetUserInvitationCountQuery,
  useAdminSendTestNotificationMutation,
  useAdminUpsertUserInvitationCountMutation,
  useAdminUpsertUserNotificationSettingsMutation,
  useAdminUserDetailsQuery,
  useAdminUserNotificationSettingsQuery,
  useProfileGroupingsBySlugQuery,
} from "../__generated__/apollo-hooks";
import {
  GqlOps,
  useProfilePrivacySettings340Query,
  useUpdateFacePrivacySettingsMutation,
  useUpdateMyPrivacySettingsMutation,
} from "shared/dist/__generated__/components";
import { H3, H4 } from "shared-web-react/dist/widgets/text";
import { Link, Outlet } from "react-router-dom";
import { Spinner, SpinnerCentered, SpinnerFullScreen } from "shared-web-react/dist/widgets/spinner";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "shared-web-react/dist/widgets/floating-ui/tooltip";
import { faCheckSquare, faSquare, faTriangleExclamation } from "@fortawesome/pro-solid-svg-icons";

import { AdminUserFlags } from "./feature-flags";
import { Avatar } from "shared-web-react/dist/widgets/avatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GenderPicker } from "shared-web-react/dist/widgets/gender-picker";
import React from "react";
import { SpinnerButton } from "shared-web-react/dist/widgets/spinner-button";
import { SpinnerCheckbox } from "shared-web-react/dist/widgets/spinner-checkbox";
import { UserPermanentlyDeleteButton } from "./user-hard-delete";
import { UserSoftDeleteToggle } from "./user-soft-delete";
import { adminRoutes } from "../util/admin-routes";
import clsx from "clsx";
import { useAddToast } from "shared-web-react/dist/widgets/toast-provider";
import { useConfirm } from "shared-web-react/dist/widgets/confirm-provider";
import { useMyRoles } from "shared/dist/auth-data";
import { useTypedParams } from "react-router-typesafe-routes/dom";

export function UserDetail(): React.JSX.Element {
  const slug = useTypedParams(adminRoutes.USERS.DETAIL).slug;
  const isSuperAdmin = useMyRoles()?.includes("super_admin_user");
  // const { data: groupingDataRaw } = useProfileGroupingsBySlugQuery({
  //   variables: { slug },
  //   skip: !slug,
  // });
  const userDetailsResult = useAdminUserDetailsQuery({
    skip: !slug,
    variables: {
      slug,
    },
  });
  const user = userDetailsResult?.data?.admin_user_summaries?.[0];
  return (
    <div
      className={clsx(
        "space-y-2 pl-2 pb-4 h-full flex flex-col justify-stretch max-h-full overflow-scroll relative",
        user?.deleted_at && "  opacity-75"
      )}
    >
      <div
        className={clsx(
          "space-y-2  p-2 flex flex-col justify-stretch max-h-full overflow-scroll relative",
          user?.deleted_at && "bg-red-100"
        )}
      >
        <H3 className={clsx("flex-0")}>
          {user?.screen_name} {"@" + slug}
        </H3>
        {user?.deleted_at && (
          <H4>
            <FontAwesomeIcon icon={faTriangleExclamation} /> Soft Deleted on{" "}
            {fmtDt(user.deleted_at)}
          </H4>
        )}
        <p className={clsx("italic text-sm flex-0")}>{user?.id}</p>
        <div className={clsx("text-sm flex-0 space-x-2")}>
          <span>{userDetailsResult?.data?.admin_user_summaries?.[0]?.phone_number}</span>
          <span>{userDetailsResult?.data?.admin_user_summaries?.[0]?.email}</span>

          <Tooltip>
            <TooltipTrigger className={clsx("inline")}>
              <FontAwesomeIcon
                icon={
                  userDetailsResult?.data?.admin_user_summaries?.[0]?.email_is_verified
                    ? faCheckSquare
                    : faSquare
                }
              />
            </TooltipTrigger>
            <TooltipContent style={{ float: "left" }}>
              email{" "}
              {userDetailsResult?.data?.admin_user_summaries?.[0]?.email_is_verified
                ? "verified"
                : "unverified"}
            </TooltipContent>
          </Tooltip>
        </div>
        <div className={clsx("w-64")}>
          <Tooltip>
            <TooltipContent>set user gender</TooltipContent>
            <TooltipTrigger>
              <GenderPicker
                placeholder="- NULL -"
                forUserId={user?.id}
                labelClassName="hidden"
                size="sm"
                isAdmin
                hideDescriptions
              />
            </TooltipTrigger>
          </Tooltip>
        </div>
        <div className="w-full flex flex-row justify-start items-center gap-2 flex-0">
          <span className={clsx("font-bold mr-2")}>Vouches:</span>
          written: {userDetailsResult.data?.vouches_written?.aggregate?.count ?? ""} received:{" "}
          {userDetailsResult.data?.vouches_about?.aggregate?.count ?? ""}
        </div>
        <div className="w-full flex flex-row justify-start items-center gap-2 flex-0">
          Verification Status:
          {user?.id ? (
            <div className={clsx("max-w-[24rem]")}>
              <AdminVerificationToggle
                user_id={user.id!}
                source={user.identity_verifications?.[0]?.source}
                status={user.identity_verifications?.[0]?.success}
              />
            </div>
          ) : (
            <span>error</span>
          )}
        </div>
        <div className="w-full flex flex-row justify-start items-center gap-2 flex-0">
          Profile Review Status:
          {user?.id ? (
            <div className={clsx("max-w-[24rem]")}>
              <AdminOnboardingStatusToggle user_id={user.id!} />
            </div>
          ) : (
            <span>error</span>
          )}
        </div>
      </div>
      <div className={clsx("tabs-lg tabs-lifted flex-0 tabs bg-base-100")}>
        <AdminTab end to={adminRoutes.USERS.DETAIL.buildPath({ slug })}>
          home
        </AdminTab>
        <AdminTab to={adminRoutes.USERS.DETAIL.PHOTOS.buildPath({ slug })}>photos</AdminTab>
        <AdminTab to={adminRoutes.USERS.DETAIL.RELATIONSHIPS.buildPath({ slug })}>
          relationships
        </AdminTab>
        <AdminTab to={adminRoutes.USERS.DETAIL.DISCOVERY.buildPath({ slug })}>discovery</AdminTab>
        <AdminTab to={adminRoutes.USERS.DETAIL.VOUCHES.buildPath({ slug })}>vouch details</AdminTab>
        <AdminTab to={adminRoutes.USERS.DETAIL.DELETE.buildPath({ slug })}>delete</AdminTab>
        {isSuperAdmin && (
          <AdminTab to={adminRoutes.USERS.DETAIL.ID_MEDIA.buildPath({ slug })}>id media</AdminTab>
        )}
      </div>
      <div className={clsx("flex-1 overflow-auto")}>
        <Outlet />
      </div>
    </div>
  );
}

export function UserDetailDelete(): React.JSX.Element {
  const slug = useTypedParams(adminRoutes.USERS.DETAIL).slug;
  const { data: groupingDataRaw, loading } = useProfileGroupingsBySlugQuery({
    variables: { slug },
    skip: !slug,
  });
  const userId = groupingDataRaw?.admin_user_summaries?.[0]?.id;
  if (loading) {
    return <SpinnerCentered />;
  }
  return userId ? (
    <div className="flex flex-col h-full justify-stretch items-stretch">
      <div className="flex-1">
        <UserSoftDeleteToggle {...{ slug, userId }} />
      </div>
      <div className={clsx("flex-0 flex justify-end items-center px-2 gap-2")}>
        <p>Note that users who own events cannot be deleted</p>
        <UserPermanentlyDeleteButton userId={userId} slug={slug} />
      </div>
    </div>
  ) : (
    <div>missing user id</div>
  );
}

export function UserDetailBasics(): React.JSX.Element {
  const slug = useTypedParams(adminRoutes.USERS.DETAIL).slug;
  const { data: groupingDataRaw, loading } = useProfileGroupingsBySlugQuery({
    variables: { slug },
    skip: !slug,
  });
  const groupingData = groupingDataRaw?.admin_user_summaries?.[0];
  const userId = groupingDataRaw?.admin_user_summaries?.[0]?.id;
  if (loading) {
    return <SpinnerFullScreen />;
  }
  return (
    <div className="space-y-2 py-4 flex flex-col justify-stretch max-h-full overflow-auto relative">
      <div className="w-full flex flex-row justify-start items-center gap-2">
        <Link
          className="btn btn-primary btn-sm"
          to={adminRoutes.PROFILE_GROUPING.DETAIL.buildPath({ slug: slug })}
        >
          Go to user grouping
        </Link>
        {loading && <Spinner />}
        {groupingData?.groupings?.length && (
          <>
            <div>User Groups: </div>
            {groupingData?.groupings?.map((g) => (
              <div key={g.owner_id}>
                {g.owner_summary?.screen_name ?? g.owner_id}: {g.value}
              </div>
            ))}
          </>
        )}
      </div>
      <div className="divider" />
      {userId && (
        <div className={clsx("flex flex-row h-full items-stretch justify-stretch")}>
          <div className="flex-1">
            <AdminUserFlags user_id={userId} />
          </div>
          <div className="divider divider-horizontal flex-0 " />
          <div className="flex-1">
            <AdminUserNotificationSettings user_id={userId} />
          </div>
          <div className="divider divider-horizontal flex-0 " />
          <div className="flex-1">
            <AdminUserAddInvitation user_id={userId} />
          </div>
        </div>
      )}
    </div>
  );
}

function AdminSendTestNotificationWidget({ user_id }: { user_id: string }): React.JSX.Element {
  const [trigger, setTrigger] = React.useState<null | L_Notification_Test_Trigger>(null);
  const confirm = useConfirm();
  const [mutate, { loading }] = useAdminSendTestNotificationMutation();
  const send = React.useCallback(async () => {
    if (!trigger) return;
    confirm({
      title: "Send notification to this user? ",
      content: "You probably do not want to do this to real users",
      onOk: async () => mutate({ variables: { user_id, trigger } }).then(() => setTrigger(null)),
    });
  }, [user_id, confirm, trigger, mutate, setTrigger]);
  return (
    <div className="flex flex-row gap-2 items-center justify-start">
      <span>Send Test Notification</span>

      <EnumSelect
        inputClassName="min-w-[20em]"
        value={trigger}
        possibleValues={L_Notification_Test_Trigger}
        onChange={(t) => setTrigger(t)}
      />
      <SpinnerButton
        className="btn btn-warning"
        disabled={!trigger || loading}
        onClickWrapped={() => send()}
      >
        send
      </SpinnerButton>
    </div>
  );
}

function AdminUserNotificationSettings({ user_id }: { user_id: string }): React.JSX.Element {
  const [upsert] = useAdminUpsertUserNotificationSettingsMutation();
  const {
    data: notificationSettingsData,
    loading: notificationLoading,
    called,
  } = useAdminUserNotificationSettingsQuery({
    variables: { user_id: user_id! },
    skip: !user_id,
  });

  const { data: profilePrivacyData, loading: profilePrivacyLoading } =
    useProfilePrivacySettings340Query({
      variables: { my_id: user_id },
      skip: !user_id,
    });

  console.log(
    "🚀 - file: profile-privacy-settings.tsx:49 - ProfilePrivacySettings - data:",
    notificationSettingsData
  );
  const [updateFaceVisibility, { loading: updateFaceLoading }] =
    useUpdateFacePrivacySettingsMutation();
  const [updateProfileVisibility, { loading: updateLoading }] =
    useUpdateMyPrivacySettingsMutation();

  const settings = notificationSettingsData?.user_notification_settings_by_pk;
  const addToast = useAddToast();
  const loading =
    updateLoading || updateFaceLoading || profilePrivacyLoading || notificationLoading;
  React.useEffect(() => {
    if (user_id && called && !loading && !settings) {
      upsert({
        refetchQueries: ["AdminUserNotificationSettings"],
        variables: { input: { user_id } },
      });
    }
  }, [user_id, upsert, settings, loading, called]);
  // const userFlags = data?.?.map((f) => f.feature_id);
  if (!user_id || !settings) return <></>;

  const settingsClean = Object.fromEntries(
    Object.entries(settings).filter(([flag]) => flag !== "__typename")
  );
  return (
    <div className={clsx("pb-16 flex flex-col justify-start items-start gap-3")}>
      <H4 className={clsx("mr-3")}>Notification Settings:</H4>
      <AdminSendTestNotificationWidget user_id={user_id} />
      {loading && !notificationSettingsData?.user_notification_settings_by_pk ? (
        <Spinner />
      ) : (
        Object.entries(settingsClean).map(([flag, value]) => (
          <SpinnerCheckbox
            key={flag}
            checked={!!value}
            className="justify-self-center"
            onChangeWrapped={async () =>
              upsert({
                refetchQueries: ["AdminUserNotificationSettings"],
                variables: { input: { ...settingsClean, user_id, [flag]: !value } },
              })
            }
          >
            {flag}
          </SpinnerCheckbox>
        ))
      )}
      <div className="divider" />
      <div className="w-full">
        <EnumSelectWithDesc
          possibleValues={Profile_Privacy_Levels_Enum}
          inputClassName="w-full"
          value={profilePrivacyData?.user_profile_privacy_settings_by_pk?.profile_privacy_level}
          toDesc={(level) => {
            return splitSep(
              profilePrivacyData?.profile_privacy_levels?.filter((v) => v.value === level)?.[0]
                ?.localized_string?.en_US
            );
          }}
          onChange={async (value) => {
            if (!value) return;
            updateProfileVisibility({
              variables: { my_id: user_id, level: value },
              refetchQueries: [GqlOps.Query.ProfilePrivacySettings340],
            }).then(() => addToast({ content: "Saved!" }));
          }}
        />
      </div>
      <div className="w-full pb-24">
        <p>Facial Privacy and Blurring</p>
        <EnumSelectWithDesc
          possibleValues={Face_Privacy_Levels_Enum}
          inputClassName="w-full"
          value={profilePrivacyData?.user_profile_privacy_settings_by_pk?.face_privacy_level}
          toDesc={(level) => {
            console.log(
              "🚀 - file: user-detail.tsx:334 - AdminUserNotificationSettings - level:",
              level,
              profilePrivacyData
            );
            return splitSep(
              profilePrivacyData?.face_privacy_levels?.filter((v) => v.value === level)?.[0]
                ?.localized_string?.en_US
            );
          }}
          onChange={async (value) => {
            if (!value) return;
            updateFaceVisibility({
              variables: { my_id: user_id, level: value },
              refetchQueries: [GqlOps.Query.ProfilePrivacySettings340],
            }).then(() => addToast({ content: "Saved!" }));
          }}
        />
      </div>
    </div>
  );
}

function AdminUserAddInvitation({ user_id }: { user_id: string }): React.JSX.Element {
  const [totalUnused, setTotalUnused] = React.useState(0);
  const [totalAllowed, setTotalAllowed] = React.useState(0);
  const [mutate] = useAdminUpsertUserInvitationCountMutation();
  const addToast = useAddToast();
  const { data, loading, called } = useAdminGetUserInvitationCountQuery({
    variables: { user_id: user_id },
  });
  const invitationCountData = data?.allowed_invitation_counts?.[0];

  React.useEffect(() => {
    invitationCountData && setTotalAllowed(invitationCountData.total_allowed);
    invitationCountData && setTotalUnused(invitationCountData.total_unused_allowed);
  }, [invitationCountData, loading, called]);

  const handleClick = async () => {
    const res = await mutate({
      variables: {
        countData: {
          user_id: user_id,
          total_allowed: totalAllowed,
          total_unused_allowed: totalUnused,
        },
      },
    });

    if (res.data?.insert_allowed_invitation_counts_one) {
      addToast({ content: "success", color: "success" });
    }
  };

  return (
    <div className={clsx("pb-16 flex flex-col justify-start items-start gap-3")}>
      <H4 className={clsx("mr-3")}>Current User Invitations Overrides:</H4>
      {loading && !data?.allowed_invitation_counts ? (
        <Spinner />
      ) : (
        <div>
          <label htmlFor="">Total Allowed Invitations</label>
          <input
            type="text"
            className="input input-bordered"
            value={totalAllowed}
            onChange={(e) => {
              const parsedValue = parseInt(e.target.value);
              if (!isNaN(parsedValue)) {
                setTotalAllowed(parsedValue);
              } else if (e.target.value === "") {
                setTotalAllowed(0); // or some other default value
              }
            }}
          />
          <label htmlFor="">Total Unused Allowed Invitations</label>
          <input
            type="text"
            placeholder="Search…"
            className="input input-bordered"
            value={totalUnused}
            onChange={(e) => {
              const parsedValue = parseInt(e.target.value);
              if (!isNaN(parsedValue)) {
                setTotalUnused(parsedValue);
              } else if (e.target.value === "") {
                setTotalUnused(0); // or some other default value
              }
            }}
          />
          <button
            onClick={() => {
              handleClick();
            }}
            className="btn"
          >
            Submit
          </button>
        </div>
      )}
    </div>
  );
}

export function UserDetailRelationships(): React.JSX.Element {
  const slug = useTypedParams(adminRoutes.USERS.DETAIL).slug;
  const { data } = useAdminUserDetailsQuery({
    skip: !slug,
    variables: {
      slug,
    },
    fetchPolicy: "cache-first",
  });
  const relsToMe = data?.admin_user_summaries?.[0]?.user?.relationships_sent_to_me;
  const relsFromMe = data?.admin_user_summaries?.[0]?.user?.relationships_sent_from_me;
  return (
    <div>
      <table className="table w-full table-sm table-zebra">
        {/* head */}
        <thead>
          <tr>
            <th>id</th>
            <th>to</th>
            <th>from</th>
            <th>type</th>
            <th>status</th>
          </tr>
        </thead>
        <tbody>
          {relsToMe?.map((rel) => (
            <tr key={rel.id}>
              <td>
                <Link
                  to={adminRoutes.RELATIONSHIPS.FILTER.buildPath({ filter: rel.id })}
                  className={clsx("link")}
                >
                  <IdSpan className="link" id={rel.id} />
                </Link>
              </td>
              <td>me</td>
              {rel.from_user_summary?.slug && (
                <Link
                  to={adminRoutes.USERS.DETAIL.buildPath({
                    slug: rel.from_user_summary?.slug,
                  })}
                >
                  <Avatar
                    tailwindSize="12"
                    slug={rel.from_user_summary?.slug}
                    className={clsx("mr-2")}
                  />
                  {"@" + rel.from_user_summary?.slug}
                </Link>
              )}
              <td>{rel.type}</td>
              <td>{rel.status}</td>
            </tr>
          ))}
          {relsFromMe?.map((rel) => (
            <tr key={rel.id}>
              <td>
                <Link
                  to={adminRoutes.RELATIONSHIPS.FILTER.buildPath({ filter: rel.id })}
                  className={clsx("link")}
                >
                  <IdSpan className="link" id={rel.id} />
                </Link>
              </td>
              <td>
                {rel.to_user_summary?.slug && (
                  <Link
                    className="link"
                    to={adminRoutes.USERS.DETAIL.buildPath({
                      slug: rel.to_user_summary?.slug,
                    })}
                  >
                    <Avatar
                      tailwindSize="12"
                      slug={rel.to_user_summary?.slug}
                      className={clsx("mr-2")}
                    />
                    {"@" + rel.to_user_summary?.slug}
                  </Link>
                )}
              </td>
              <td>me</td>
              <td>{rel.type}</td>
              <td>{rel.status}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

const splitSep = (str: null | undefined | string) => {
  if (!str) return null;
  const arr = str.split("|");
  if (arr.length === 1) return str;
  if (arr.length === 2) return [arr[0], arr[1]] as [string, string];
  return null;
};
