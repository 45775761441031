import { NavLink, Outlet, useNavigate, useParams } from "react-router-dom";
import { filterNulls, uniqBy } from "shared/dist/util";

import { AdminUserPicker } from "../util/widgets";
import React from "react";
import { SpinnerCentered } from "shared-web-react/dist/widgets/spinner";
import { adminRoutes } from "../util/admin-routes";
import { useAddToast } from "shared-web-react/dist/widgets/toast-provider";
import { useAdminUsersMissingGroupsQuery } from "../__generated__/apollo-hooks";
import { useMyId } from "shared/dist/auth-data";

export function ProfileGrouping(): React.JSX.Element {
  const my_id = useMyId();
  const addToast = useAddToast();
  const navigate = useNavigate();
  const paramsSlug: string | undefined = useParams()?.slug;
  const [skippedProfilesSlugs, setSkippedProfileSlugs] = React.useState<Array<string>>([]);
  const { data, loading, refetch } = useAdminUsersMissingGroupsQuery({
    skip: !my_id,
    variables: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      skipped_slugs: skippedProfilesSlugs,
      my_id: my_id!,
    },
  });
  const profileQueue = filterNulls(data?.admin_user_summaries?.map((u) => u.slug) ?? []).filter(
    (s) => s !== paramsSlug && !skippedProfilesSlugs.includes(s)
  );
  const nextSlug = profileQueue[0];

  React.useEffect(() => {
    if (paramsSlug) return;
    if (nextSlug) navigate(adminRoutes.PROFILE_GROUPING.DETAIL.buildPath({ slug: nextSlug }));
  }, [nextSlug, navigate, paramsSlug]);
  const onNext = React.useCallback(() => {
    if (paramsSlug) setSkippedProfileSlugs(uniqBy([...skippedProfilesSlugs, paramsSlug], (a) => a));
    if (nextSlug) {
      navigate(adminRoutes.PROFILE_GROUPING.DETAIL.buildPath({ slug: nextSlug }));
      return;
    }
    addToast({ content: "No profiles left, going home" });
    refetch();
    navigate(adminRoutes.USERS.buildPath({}));
  }, [addToast, navigate, paramsSlug, nextSlug, refetch]);
  if (loading) return <SpinnerCentered />;

  return (
    <div className="py-4 flex h-full flex-col justify-stretch overflow-hidden relative">
      <div className="flex flex-0 flex-row justify-start items-center gap-2 p-1">
        <AdminUserPicker
          onSelect={(e) =>
            e && navigate(adminRoutes.PROFILE_GROUPING.DETAIL.buildPath({ slug: e.slug }))
          }
          inputClassName="input-sm"
        />
        <div>Upcoming profiles:</div>
        {(profileQueue ?? []).map((slug) => (
          <NavLink
            to={adminRoutes.PROFILE_GROUPING.DETAIL.buildPath({ slug })}
            className={({ isActive }) => (isActive ? "font-bold" : "")}
            key={slug}
          >
            {"@" + slug}
          </NavLink>
        ))}
      </div>
      <Outlet context={{ onNext, nextLabel: nextSlug ? "next profile" : "Back to users" }} />
    </div>
  );
}
