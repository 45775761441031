import {
  AdminEventListQuery,
  Admin_Event_SummaryFragment,
  Events_Order_By,
  GqlOps,
  useAdminChangeEventOwnerMutation,
  useAdminEventDetailsSuspenseQuery,
  useAdminEventListQuery,
  useAdminSetEventDeletedMutation,
} from "../__generated__/apollo-hooks";
import {
  AdminUserAvatar,
  AdminUserPicker,
  ColHeader,
  ColHeaderProps,
  DateTd,
  IdSpan,
  fmtDt,
} from "../util/widgets";
import { H1, H3, H4 } from "shared-web-react/dist/widgets/text";
import { Spinner, SpinnerCentered } from "shared-web-react/dist/widgets/spinner";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Paginator } from "shared-web-react/dist/widgets/paginator";
import React from "react";
import { SpinnerButton } from "shared-web-react/dist/widgets/spinner-button";
import { UserIdSlugSn } from "../util/types";
import { adminRoutes } from "../util/admin-routes";
import clsx from "clsx";
import { faXmarkCircle } from "@fortawesome/pro-solid-svg-icons";
import { useConfirm } from "shared-web-react/dist/widgets/confirm-provider";
import { useDebounce } from "use-debounce";
import { useTypedParams } from "react-router-typesafe-routes/dom";

export function AdminEventDetail(): React.JSX.Element {
  return (
    <React.Suspense fallback={<SpinnerCentered className="my-8" />}>
      <AdminEventDetailInner />
    </React.Suspense>
  );
}

function AdminChangeEventOwner({
  event,
}: {
  event: Admin_Event_SummaryFragment;
}): React.JSX.Element {
  const [showChangePicker, setShowChangePicker] = React.useState(false);
  const [mutate] = useAdminChangeEventOwnerMutation();
  const [newOwner, setNewOwner] = React.useState<null | UserIdSlugSn>(null);
  return (
    <div>
      <H4>
        Owner: <AdminUserAvatar slug={event.owner_summary?.slug} />
      </H4>
      <div className="flex flex-row gap-1 items-center justify-start my-1 h-12">
        <button className="link" onClick={() => setShowChangePicker(!showChangePicker)}>
          {showChangePicker ? "cancel" : "change owner"}
        </button>
        {showChangePicker && (
          <>
            <AdminUserPicker onSelect={setNewOwner} />
            <SpinnerButton
              className="btn btn-sm"
              onClickWrapped={async () => {
                if (!newOwner) return;
                return mutate({
                  variables: { event_id: event.id, new_owner_id: newOwner.id },
                  refetchQueries: [GqlOps.Query.AdminEventDetails, GqlOps.Query.AdminEventList],
                });
              }}
              disabled={!newOwner}
            >
              Confirm
            </SpinnerButton>
          </>
        )}
      </div>
    </div>
  );
}
function AdminEventDetailInner(): React.JSX.Element {
  const { event_id } = useTypedParams(adminRoutes.EVENTS.DETAIL);
  const { data } = useAdminEventDetailsSuspenseQuery({ variables: { event_id } });
  const event = data.events_by_pk;
  if (!event) return <div>missing event data</div>;
  return (
    <div>
      <H1>{event.name}</H1>
      <p>{event.name}</p>
      <p>{event.location_description}</p>
      <AdminChangeEventOwner event={event} />
      <div className="divider" />
      <p>attendee count: {event.attendees.length}</p>
      <H3>Attendees:</H3>
      <div className={clsx("max-h-32 overflow-auto")}>
        <ul>
          {event.attendees.map((attendee) => (
            <li className="my-1" key={attendee.user_summary?.id}>
              <AdminUserAvatar slug={attendee.user_summary?.slug} />
            </li>
          ))}
        </ul>
      </div>
      <div className="divider" />
      <H3>Event Threads:</H3>
      {event.threads.map((thread) => (
        <div key={thread.id} className={clsx("max-h-32 overflow-auto")}>
          <div className="grid grid-cols-[auto-auto]">
            {thread.messages.map((message) => (
              <React.Fragment key={message.id}>
                <div className="flex flex-col items-start justify-start gap-1">
                  <AdminUserAvatar slug={message.sender_summary?.slug} />
                  <div>{fmtDt(message.created_at)}</div>
                </div>
                <div>{message.content}</div>
              </React.Fragment>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

function AdminEventTableHead(
  props: Omit<ColHeaderProps<Events_Order_By>, "label" | "value">
): React.JSX.Element {
  return (
    <thead className="sticky top-0 z-30 bg-base-100">
      <tr>
        <th></th>
        <ColHeader {...{ value: "name", label: "Name", ...props }} />
        <ColHeader {...{ value: "start", label: "start", ...props }} />
        <th>owner</th>
        <th>public</th>
        <th>attendee count</th>
        <th>location</th>
        <th>id</th>
        <th>description</th>
      </tr>
    </thead>
  );
}

function AdminEventTableRow({
  event,
}: {
  event: AdminEventListQuery["events"][0];
}): React.JSX.Element {
  const confirm = useConfirm();
  const [setDeleted, setDeletedResults] = useAdminSetEventDeletedMutation();
  return (
    <tr className={clsx({ "bg-gray-300 line-through": event.is_deleted })}>
      <td>
        <SpinnerButton
          disabled={setDeletedResults.loading || event.is_deleted}
          loading={setDeletedResults.loading}
          onClick={() =>
            confirm({
              title: "Delete Event",
              content: `Are you sure you want to delete the event: ${event.name}?`,
              onOk: () =>
                setDeleted({
                  refetchQueries: [GqlOps.Query.AdminEventList],
                  variables: { event_id: event.id, is_deleted: true },
                }),
            })
          }
        >
          <FontAwesomeIcon icon={faXmarkCircle} className="text-red-500" />
        </SpinnerButton>
      </td>
      <td className="whitespace-nowrap">
        <Link className="link" to={adminRoutes.EVENTS.DETAIL.buildPath({ event_id: event.id })}>
          {event.name}
        </Link>
      </td>
      <DateTd date={event.start} />
      <td>
        <AdminUserAvatar slug={event.owner_summary?.slug} />
      </td>
      <td>{event.is_public ? "" : "no"}</td>
      <td>{event.attendee_count?.attendee_count}</td>
      <td>{event.location_description}</td>
      <td>
        <IdSpan id={event.id} />
      </td>
      <td>{event.description?.slice(0, 255)}</td>
    </tr>
  );
}

export function AdminEvents(): React.JSX.Element {
  const limit = 50;
  const [page, setPage] = React.useState(1);
  const [field, setField] = React.useState<keyof Events_Order_By>("created_at");
  const [filter, setFilter] = React.useState("");
  const [reverse, setReverse] = React.useState(false);
  const [debouncedFilter, { isPending }] = useDebounce(filter, 1000);
  const { data, loading } = useAdminEventListQuery({
    variables: {
      limit,
      offset: (page - 1) * limit,
      ci_filter: `%${debouncedFilter}%`,
      str_filter: `%${debouncedFilter}%`,
      order_by: { [field]: reverse ? "desc" : "asc" },
    },
  });
  if (loading) return <SpinnerCentered />;

  const count = data?.count?.aggregate?.count ?? 0;
  const pageCount = count ? Math.ceil(count / limit) : 0;
  return (
    <div className="space-y-2 pt-4 flex flex-col justify-stretch max-h-screen overflow-hidden relative">
      <div className="flex flex-row justify-start items-center gap-2">
        Total: {count}
        <Paginator {...{ page, setPage, pageCount }} />
        <div className="form-control">
          <div className="join">
            <input
              type="text"
              placeholder="Search…"
              className="UserList join-item input input-bordered"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
            <button onClick={() => setFilter("")} className="join-item btn">
              reset
            </button>
          </div>
        </div>
        {(loading || isPending()) && <Spinner />}
      </div>
      <div className="overflow-auto w-full ">
        <table className="table w-full max-w-full min-w-min overflow-auto table-sm ">
          {/* head */}
          <AdminEventTableHead {...{ reverse, setReverse, field, setField }} />
          <tbody>
            {data?.events?.map((event) => <AdminEventTableRow {...{ event }} key={event.id} />)}
          </tbody>
        </table>
      </div>
    </div>
  );
}
