import {
  GqlOps,
  useProfileGroupingsBySlugQuery,
  useSaveGroupingMutation,
} from "../__generated__/apollo-hooks";
import { H1, H3 } from "shared-web-react/dist/widgets/text";
import { useKeyPress, useKeyPressEvent } from "react-use";

import { SpinnerFullScreen } from "shared-web-react/dist/widgets/spinner";
import { adminRoutes } from "../util/admin-routes";
import clsx from "clsx";
import { useAddToast } from "shared-web-react/dist/widgets/toast-provider";
import { useBioFromSlugQuery } from "shared/dist/__generated__/components";
import { useOutletContext } from "react-router-dom";
import { useTypedParams } from "react-router-typesafe-routes/dom";

export function ProfileGroupingDetail(): React.JSX.Element {
  const slug = useTypedParams(adminRoutes.USERS.DETAIL).slug;
  const [modDown] = useKeyPress("Alt");
  const { data: groupingData } = useProfileGroupingsBySlugQuery({
    skip: !slug,
    variables: { slug },
  });
  const [save] = useSaveGroupingMutation();
  useKeyPressEvent(
    (e) => e.altKey && (e.code === "KeyS" || e.code.startsWith("Digit")),
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    () => {},
    (e) => {
      if (!e.altKey) return;
      if (e.code === "KeyS") return onNext();
      const val = parseInt(e.code.replace("Digit", ""));
      if (isNaN(val)) return;
      saveGrouping(val);
    }
  );
  const { data: bioData, loading: bioLoading } = useBioFromSlugQuery({
    skip: !slug,
    variables: { slug },
  });
  const { onNext, nextLabel } = useOutletContext<{ onNext: () => void; nextLabel: string }>();
  const addToast = useAddToast();
  const saveGrouping = async (group: number) => {
    const subject_id = groupingData?.admin_user_summaries?.[0]?.id;
    if (!subject_id) return;

    addToast({
      content: `Ranked @${slug} to ${group}`,
      className: "alert-success",
    });
    save({
      variables: { value: group, subject_id },
      refetchQueries: [GqlOps.Query.AdminUserList, GqlOps.Query.AdminUsersMissingGroups],
    });
    onNext();
  };
  if (bioLoading) return <SpinnerFullScreen />;
  const hasImages = !!(bioData?.l_bio_public?.images?.length ?? 0 > 0);

  return (
    <div className="flex h-full w-full max-h-full flex-col pt-6 gap-4 justify-stretch items-center px-2 pb-4">
      <H1 className={clsx("flex-0")}>
        {bioData?.l_bio_public?.user?.screen_name} / {"@" + slug}
      </H1>
      <p>
        <em>Alt/Option + number to rank</em>
      </p>
      {!hasImages && !bioLoading && (
        <div className={clsx("w-full h-full flex-col-center-center ")}>
          <H3>No images</H3>
          <button className={clsx("btn btn-lg")} onClick={onNext}>
            {nextLabel}
          </button>
        </div>
      )}
      {hasImages && (
        <div className="flex-1 min-h-0 py-2 max-w-[100vw] overflow-x-auto gap-2 grid-rows-2 grid grid-flow-col items-center justify-items-center p-2 bg-base-200">
          {bioData?.l_bio_public?.images?.map((img, idx) => (
            <img className="max-h-full" src={img.presigned_url} key={img.id ?? idx} />
          ))}
        </div>
      )}
      <div className="flex flex-row w-full justify-stretch gap-4 py-2">
        {[...Array(10)].map((_, idx) => (
          <button
            key={idx + 1}
            onClick={() => hasImages && saveGrouping(idx + 1)}
            disabled={!hasImages}
            className={clsx("btn-secondary btn btn-lg flex-1", modDown && "underline")}
          >
            {idx + 1}
          </button>
        ))}
      </div>
    </div>
  );
}
